import { combineReducers } from "redux";
import { CurrentThemeState, currentThemeReducer } from "./current";
import { themeActions } from "./actions";
import * as themeSelectors from "./selectors";

export interface ThemesState {
    current: CurrentThemeState;
}

const themeReducer = combineReducers({
    current: currentThemeReducer
});

export default themeReducer;

export { themeActions, themeReducer, themeSelectors };
