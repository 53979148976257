import * as React from "react";
import {Redirect, RouteComponentProps} from "react-router";
import LibraryLayout from "../../LibraryLayout/LibraryLayout";
import FormBaseComponent, {OptionalFormBaseComponentState} from "components/FormBaseComponent";
import FormPaperLayout from "components/FormPaperLayout";
import {
    ExpandableFormSection,
    Summary,
    SummaryNode,
    FormSectionHeading,
    Text,
    required,
    MarkdownEditor,
    Sensitive,
    SensitiveFileUpload,
    Note
} from "components/form";
import {Callout, CalloutType} from "components/Callout/Callout";
import OverflowMenu from "components/Menu/OverflowMenu";
import {EnvironmentMultiSelect} from "components/MultiSelect";
import { EnvironmentChip, CertificateExpiryChip, environmentChipList } from "components/Chips";
import {secondaryText, primaryText} from "theme/colors";
import AccountBoxIcon from "material-ui/svg-icons/action/account-box";
import InfoOutlineIcon from "material-ui/svg-icons/action/info-outline";
import {FeatureToggle, Feature} from "components/FeatureToggle";
import {
    CertificateResource,
    CertificateUsageResource,
    TenantedDeploymentMode,
    TagSetResource,
    EnvironmentResource,
    TenantResource, CertificateDataFormat
} from "client/resources";
import {repository, session} from "clientInstance";
import * as _ from "lodash";
import CertificateDetail from "./CertificateDetail";
import CertificateUsage from "./CertificateUsage";
import {certificateUsageSummary, CertificateUsageEntry} from "./certificateUsageSummary";
import ReplaceCertificate from "./ReplaceCertificate";
import {ArchiveAction, default as ArchiveCertificate} from "./ArchiveCertificate";
import TenantedDeploymentParticipationSelector from "components/TenantedDeploymentParticipationSelector";
const styles = require("./style.less");
import ThumbprintText from "components/ThumbprintText";
import CommonSummaryHelper from "utils/CommonSummaryHelper";
import Markdown from "components/Markdown/index";
import {AdvancedTenantsAndTenantTagsSelector} from "components/AdvancedTenantSelector";
import DownloadCertificate from "areas/library/components/Certificates/Certificate/DownloadCertificate";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import StringHelper from "utils/StringHelper";
import routeLinks from "../../../../../routeLinks";
import {isAllowed} from "components/PermissionCheck/PermissionCheck";
import InternalRedirect from "../../../../../components/Navigation/InternalRedirect/InternalRedirect";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";

interface CertificateProps extends RouteComponentProps<CertificateRouteParams> {
    create?: boolean;
}

interface CertificateRouteParams {
    certificateId: string;
}

interface CertificateState extends OptionalFormBaseComponentState<CertificateResource> {
    deleted: boolean;
    newId: string;
    allEnvironments: EnvironmentResource[];
    allTenants: TenantResource[];
    openTennantPreview: boolean;
    certificateUsages: CertificateUsageEntry[];
    replacedByCertificate: CertificateResource;
}

class Certificate extends FormBaseComponent<CertificateProps, CertificateState, CertificateResource> {
    constructor(props: CertificateProps) {
        super(props);
        this.state = {
            deleted: false,
            newId: null,
            allEnvironments: [],
            allTenants: [],
            openTennantPreview: false,
            certificateUsages: null,
            replacedByCertificate: null
        };
    }

    async componentDidMount() {
        let certificate: CertificateResource = null;
        let allEnvironments: EnvironmentResource[] = null;
        let allTenants: TenantResource[] = null;
        let certificateUsages: CertificateUsageEntry[] = null;
        let replacedByCertificate: CertificateResource = null;

        if (this.props.create) {
            certificate = {
                Id: null as string,
                Name: "",
                Notes: "",
                CertificateData: {NewValue: null, HasValue: false},
                Password: {NewValue: null, HasValue: false},
                EnvironmentIds: [],
                TenantIds: [],
                TenantTags: [],
                TenantedDeploymentParticipation: TenantedDeploymentMode.Untenanted,
                Links: null,
            };
        } else {
            await this.doBusyTask(async () => {
                certificate = await repository.Certificates.get(this.props.match.params.certificateId);
                const certificateUsageData = await repository.CertificateConfiguration.usage(certificate);
                certificateUsages = certificateUsageSummary(certificateUsageData);

                if (certificate.ReplacedBy) {
                    replacedByCertificate = await repository.Certificates.get(certificate.ReplacedBy);
                }
            });
        }

        await this.doBusyTask(async () => {
            [allEnvironments, allTenants] =
                await Promise.all<EnvironmentResource[], TenantResource[]>([
                    repository.Environments.all(),
                    repository.Tenants.all()
                ]);
        });

        this.setState({
            model: certificate,
            cleanModel: _.cloneDeep(certificate),
            allEnvironments,
            allTenants,
            certificateUsages,
            replacedByCertificate
        });
    }

    render() {
        const title = this.props.create
            ? "New Certificate"
            : this.state.model
                ? this.state.model.Name
                : StringHelper.ellipsis;

        const overFlowActions = [];
        if (this.state.model && !this.props.create) {
            const download = <DownloadCertificate certificate={this.state.model}/>;
            overFlowActions.push(OverflowMenu.dialogItem("Download", download));

            const replace = <ReplaceCertificate certificate={this.state.model}
                                                afterCertificateReplace={(c) => this.handleCertificateReplaced(c)}/>;
            overFlowActions.push(OverflowMenu.dialogItem("Replace", replace, {permission: Permission.CertificateEdit, wildcard: true}));

            if (this.state.model.Archived) {
                overFlowActions.push(OverflowMenu.deleteItemDefault("certificate", this.handleDeleteConfirm, {permission: Permission.CertificateDelete, wildcard: true}));
                if (!this.state.model.ReplacedBy) {
                    const unarchive = <ArchiveCertificate certificate={this.state.model}
                                                          action={ArchiveAction.Unachive}
                                                          afterAction={() => this.handleArchive()}/>;
                    overFlowActions.push(OverflowMenu.dialogItem("Unarchive", unarchive, {permission: Permission.CertificateEdit, wildcard: true}));
                }
            } else {
                const archive = <ArchiveCertificate certificate={this.state.model}
                                                    action={ArchiveAction.Archive}
                                                    afterAction={() => this.handleArchive()}/>;
                overFlowActions.push(OverflowMenu.dialogItem("Archive", archive, {permission: Permission.CertificateEdit, wildcard: true}));
            }

            overFlowActions.push([OverflowMenu.navItem("Audit Trail",
                routeLinks.configuration.eventsRegardingAny([this.state.model.Id]), null, {
                    permission: Permission.EventView,
                    wildcard: true
                })]);
        }

        const saveText: string = this.state.newId
            ? "Certificate created"
            : "Certificate details updated";

        return <LibraryLayout {...this.props}>
            <FormPaperLayout
                title={title}
                breadcrumbTitle={"Certificates"}
                breadcrumbPath={routeLinks.library.certificates.root}
                busy={this.state.busy}
                errors={this.state.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                savePermission={{permission: this.props.create ? Permission.CertificateCreate : Permission.CertificateEdit, wildcard: true}}
                onSaveClick={() => this.handleSaveClick()}
                saveText={saveText}
                expandAllOnMount={this.props.create}
                overFlowActions={overFlowActions}
            >
                {this.state.deleted && <InternalRedirect to={routeLinks.library.certificates.root}/>}
                {this.state.newId && <InternalRedirect to={routeLinks.library.certificate(this.state.newId)}/>}
                {this.state.model && <TransitionAnimation>
                    {this.state.replacedByCertificate &&
                    <Callout title="Replaced" type={CalloutType.Information}>
                        This certificate was replaced by certificate with
                        thumbprint <ThumbprintText thumbprint={this.state.replacedByCertificate.Thumbprint}/>
                    </Callout>
                    }
                    {this.state.model.Archived &&
                    <Callout title="Archived" type={CalloutType.Information}>
                        This certificate was archived on {this.state.model.Archived}
                    </Callout>
                    }
                    {this.state.model.CertificateDataFormat === CertificateDataFormat.Unknown &&
                    <Callout title="Invalid Certificate" type={CalloutType.Warning}>This certificate was
                        unable to be parsed and may be in an invalid format.
                        This certificate will not be able to be used in Octopus deployments and you may need to upload a
                        new
                        certificate which can be correctly loaded.</Callout>}
                    <ExpandableFormSection
                        errorKey="Name"
                        title="Name"
                        focusOnExpandAll
                        summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your certificate")}
                        help="A short, memorable, unique name for this certificate.">
                        <Text
                            value={this.state.model.Name}
                            onChange={Name => this.setModelState({Name})}
                            label="Name"
                            error={this.getFieldError("Name")}
                            validate={required("Please enter a certificate name")}
                            autoFocus={true}
                        />
                    </ExpandableFormSection>
                    <ExpandableFormSection
                        errorKey="Notes"
                        title="Notes"
                        summary={this.notesSummary()}
                        help="This summary will be presented to users when selecting the certificate for inclusion in a variable.">
                        <MarkdownEditor
                            value={this.state.model.Notes}
                            label="Notes"
                            onChange={Notes => this.setModelState({Notes})}
                        />
                    </ExpandableFormSection>
                    {!this.props.create && this.state.model.CertificateDataFormat !== CertificateDataFormat.Unknown && <div>
                        <ExpandableFormSection
                            errorKey="Details"
                            title="Details"
                            summary={this.detailsSummary()}
                            help="Certificate details.">
                            <CertificateDetail certificate={this.state.model}/>
                            {this.state.model.CertificateChain.length > 0 && <div>
                                <h4>Certificate Chain</h4>
                                {this.state.model.CertificateChain.map(
                                    (cert, index) => <div key={index}>
                                        <CertificateDetail certificate={cert}/>
                                        <br/>
                                        <br/>
                                    </div>)
                                }
                            </div>
                            }
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            errorKey="Usage"
                            title="Usage"
                            summary={this.usageSummary()}
                            help="This certificate can be referenced by variables">
                            <CertificateUsage certificateUsage={this.state.certificateUsages}/>
                        </ExpandableFormSection>
                    </div>}
                    {this.props.create && this.state.model.CertificateDataFormat !== CertificateDataFormat.Unknown  && <div>
                        <FormSectionHeading title="Certificate"/>
                        <ExpandableFormSection
                            errorKey="CertificateData"
                            title="Certificate File"
                            summary={Summary.summary("Supported formats: PFX (PKCS #12), DER, PEM")}
                            help="Certificate File">
                            <SensitiveFileUpload
                                label="Certificate File"
                                value={this.state.model.CertificateData}
                                onChange={CertificateData => this.setModelState({CertificateData})}
                                error={this.getFieldError("CertificateData")}/>
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            errorKey="Password"
                            title="Password"
                            summary={Summary.summary("The password protecting the file (if required).")}
                            help="Password">
                            <Sensitive
                                value={this.state.model.Password}
                                onChange={Password => this.setModelState({Password})}
                                label="Password"
                                error={this.getFieldError("Password")}
                            />
                        </ExpandableFormSection>
                    </div>}
                    <FormSectionHeading title="Restrictions"/>
                    <ExpandableFormSection
                        errorKey="Environments"
                        title="Environments"
                        summary={this.environmentsSummary()}
                        help="Choose the environments that are allowed to use this certificate.">
                        <Note>If this field is left blank, the certificate can be used for deployments to any environment.
                            Specifying environment/s (especially for production certificates) is strongly
                            recommended.</Note>
                        <EnvironmentMultiSelect
                            items={this.state.allEnvironments}
                            onChange={EnvironmentIds => this.setModelState({EnvironmentIds})}
                            value={this.state.model.EnvironmentIds}/>
                    </ExpandableFormSection>
                    <FeatureToggle feature={Feature.MultiTenancy}>
                        <PermissionCheck permission={Permission.TenantView} tenant="*">
                            <ExpandableFormSection
                                errorKey="TenantedDeploymentParticipation"
                                title="Tenanted Deployments"
                                summary={this.tenantDeploymentModeSummary()}
                                help={"Choose the kind of deployments where this certificate should be included."}>
                                <TenantedDeploymentParticipationSelector
                                    tenantMode={this.state.model.TenantedDeploymentParticipation}
                                    resourceTypeLabel="certificate"
                                    onChange={x => this.setModelState({TenantedDeploymentParticipation: x as TenantedDeploymentMode})}
                                />
                            </ExpandableFormSection>
                            {this.state.model.TenantedDeploymentParticipation !== TenantedDeploymentMode.Untenanted &&
                            <ExpandableFormSection
                                errorKey="Tenants"
                                title="Associated Tenants"
                                summary={this.tenantSummary()}
                                help={"Choose tenants this certificate should be associated with."}>
                                <AdvancedTenantsAndTenantTagsSelector
                                    tenants={this.state.allTenants}
                                    selectedTenantIds={this.state.model.TenantIds}
                                    selectedTenantTags={this.state.model.TenantTags}
                                    doBusyTask={this.doBusyTask}
                                    onChange={(TenantIds, TenantTags) => this.setModelState({TenantIds, TenantTags})}
                                    showPreviewButton={true}
                                />
                            </ExpandableFormSection>}
                        </PermissionCheck>
                    </FeatureToggle>
                </TransitionAnimation>}
            </FormPaperLayout>
        </LibraryLayout>;
    }

    notesSummary() {
        return this.state.model.Notes
            ? Summary.summary(<Markdown markup={this.state.model.Notes}/>)
            : Summary.placeholder("Notes not provided");
    }

    detailsSummary(): SummaryNode {
        return this.state.model
            ? Summary.summary(<div className={styles.row}>
                    <div className={styles.propertyContainer}>
                        <span><InfoOutlineIcon color={primaryText}/></span>
                        <span>{this.state.model.SubjectCommonName || this.state.model.SubjectOrganization || this.state.model.SubjectDistinguishedName}</span>
                    </div>
                    <div className={styles.propertyContainer}>
                        <span><AccountBoxIcon color={primaryText}/></span>
                        <span>
                        {this.state.model.SelfSigned ? (
                            "Self-Signed"
                        ) : (
                            this.state.model.IssuerCommonName || this.state.model.IssuerOrganization || this.state.model.IssuerDistinguishedName
                        )}
                    </span>
                    </div>
                    <div className={styles.propertyContainer}>
                        <CertificateExpiryChip certificate={this.state.model}/>
                    </div>
                </div>
            )
            : Summary.placeholder("Certificate details");
    }

    usageSummary(): SummaryNode {
        return this.state.certificateUsages && (this.state.certificateUsages.length > 0)
            ? this.state.certificateUsages.length > 1
                ? Summary.summary(
                    <span>This certificate is used in <b>{this.state.certificateUsages.length}</b> places</span>)
                : Summary.summary(<span>This certificate is used in <b>one</b> place</span>)
            : Summary.placeholder("This certificate is not used anywhere");
    }

    environmentsSummary(): SummaryNode {
        return this.state.model.EnvironmentIds && this.state.model.EnvironmentIds.length
            ? Summary.summary(<span>Only available for deployments to {environmentChipList(this.state.allEnvironments, this.state.model.EnvironmentIds)}</span>)
            : Summary.default("Available for deployments to any environment");
    }

    tenantDeploymentModeSummary() {
        return CommonSummaryHelper.tenantDeploymentModeSummary(
            this.state.model.TenantedDeploymentParticipation,
            this.state.model.TenantIds,
            this.state.model.TenantTags);
    }

    tenantSummary() {
        return CommonSummaryHelper.tenantSummary(
            this.state.model.TenantIds,
            this.state.model.TenantTags,
            this.state.allTenants);
    }

    handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const isNew = this.state.model.Id == null;
            const certificate = await repository.Certificates.save(this.state.model);
            this.setState({
                model: certificate,
                cleanModel: _.cloneDeep(certificate),
                newId: isNew ? certificate.Id : null,
            });
        });
    }

    handleDeleteConfirm = async () => {
        const result = await repository.Certificates.del(this.state.model);
        this.setState(state => {
            return {
                model: null,
                cleanModel: null,
                deleted: true
            };
        });
        return true;
    }

    handleCertificateReplaced(replacedCertificate: CertificateResource) {
        const cert = this.state.model;
        this.setState({
            model: replacedCertificate,
            cleanModel: _.cloneDeep(replacedCertificate),
            newId: replacedCertificate.Id,
            replacedByCertificate: cert
        });
    }

    handleArchive = async () => {
        await this.doBusyTask(async () => {
            const certificate = await repository.Certificates.get(this.props.match.params.certificateId);
            const certificateUsageData = await repository.CertificateConfiguration.usage(certificate);
            const certificateUsages = certificateUsageSummary(certificateUsageData);
            let replacedByCertificate = null;
            if (certificate.ReplacedBy) {
                replacedByCertificate = await repository.Certificates.get(certificate.ReplacedBy);
            }
            this.setState({
                model: certificate,
                cleanModel: _.cloneDeep(certificate),
                certificateUsages,
                replacedByCertificate
            });
        });
    }
}

export default Certificate;
