import * as React from "react";
import {Callout, CalloutType} from "components/Callout";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import {AccountType} from "client/resources";

interface AzurePowerShellAccountInfoProps {
    accountType: AccountType;
}

const AzurePowerShellAccountInfo = (props: AzurePowerShellAccountInfoProps) => {
    if (props.accountType === AccountType.AzureSubscription) {
        return <Callout type={CalloutType.Information} title={"Resource Manager cmdlets can not be used"}>
            When using an Azure Management Certificate account, <ExternalLink href="AzureResourceManagerCmdlets">Resource Manager</ExternalLink> cmdlets can not be
            used. To use Resource Manager cmdlets, select an <ExternalLink href="DocumentationCreatingAzureAccount">Azure Service Principal account</ExternalLink>.
        </Callout>;
    }

    if (props.accountType === AccountType.AzureServicePrincipal) {
        return <Callout type={CalloutType.Information} title={"Service Management cmdlets can not be used"}>
            When using an Azure Service Principal account, <ExternalLink href="AzureServiceManagementCmdlets">Service Management</ExternalLink> cmdlets can not be
            used. To use Service Management cmdlets, select an <ExternalLink href="DocumentationCreatingAzureAccount">Azure Management Certificate account</ExternalLink>.
        </Callout>;
    }

    return null;
};

export default AzurePowerShellAccountInfo;