import * as React from "react";
import { ListItem } from "material-ui";
import {divider} from "theme/colors";

const BorderedListItem: React.SFC<any> = props => {
    return <ListItem style={{
        borderBottom: "0.0625rem solid",
        borderColor: divider,
    }} {...props}>
        {props.children}
    </ListItem>;
};

export default BorderedListItem;