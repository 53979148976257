import * as React from "react";
import ActionTemplateSearchResource from "client/resources/actionTemplateSearchResource";
import ActionTemplateCard from "components/ActionTemplates/ActionTemplateCard";
import { NavigationButton, NavigationButtonType } from "components/Button/NavigationButton";
import matchesFilter from "components/ActionTemplates/matchesFilter";
import { Callout, CalloutType } from "components/Callout/Callout";
import { Permission } from "client/resources/permission";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import ActionTemplateCardList from "components/ActionTemplates/ActionTemplateCardList";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { ActionTemplateCategoryResource, ActionHandlerCategory } from "client/resources";
import Section from "components/Section";
import ActionTemplateCategory from "components/ActionTemplates/ActionTemplateCategory";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import { flatten } from "lodash";

const styles = require("./InstalledActionTemplateList.less");

interface InstalledActionTemplateListProps {
    templates: ActionTemplateSearchResource[];
    communityTemplates: ActionTemplateSearchResource[];
    categories: ActionTemplateCategoryResource[];
    filter?: string;
    onPostSelectionUrlRequested?: (template: { Type: string, Id: string }) => string;
    onDetailsUrlRequested?: (template: ActionTemplateSearchResource) => string;
    onCategorySelected?: (category: ActionHandlerCategory) => void;
}

interface InstalledActionTemplateListState {
    categoriesLookup?: Array<{ name: string; templates: ActionTemplateSearchResource[] }>;
    categoryTemplates?: ActionTemplateSearchResource[];
    activeCategory?: ActionHandlerCategory;
}

class InstalledActionTemplateList extends React.Component<InstalledActionTemplateListProps, InstalledActionTemplateListState> {

    constructor(props: InstalledActionTemplateListProps) {
        super(props);
        this.state = {
            categoriesLookup: this.categoriseTemplates(props.templates),
            categoryTemplates: [],
            activeCategory: null,
        };
    }

    categoriseTemplates(templates: ActionTemplateSearchResource[]): Array<{ name: string; templates: ActionTemplateSearchResource[] }> {
        const sortedCategories = this.props.categories.sort((a, b) => {
            return (a.DisplayOrder - b.DisplayOrder);
        });
        return sortedCategories
            .filter(x => !!x) // In case there's any bad data from the API / null categories.
            .map(category => ({ name: category.Id, templates: templates.filter(t => t.Categories.includes(category.Id)) }));
    }

    showCategoryTemplates(category: ActionHandlerCategory, templates: ActionTemplateSearchResource[]) {
        this.setState({ activeCategory: category, categoryTemplates: templates }, () => {
            if (this.props.onCategorySelected) {
                this.props.onCategorySelected(category);
            }
        });
    }

    componentWillUpdate(nextProps: InstalledActionTemplateListProps) {
        if (nextProps.filter !== this.props.filter) {
            // Clear any active selection if we receive new props.
            this.showCategoryTemplates(null, null);
            if (this.props.onCategorySelected) {
                this.props.onCategorySelected(null);
            }
        }
    }

    render() {
        const isFiltering = !!this.props.filter;
        const filteredResults = this.props.templates.filter(at => matchesFilter(at, this.props.filter));
        return <div className={styles.container}>
            <Section sectionHeader={"What type of step to you want to add?"} />
            <React.Fragment>
                <Section className={styles.sectionDivider}>
                    {!isAllowed({ permission: Permission.ActionTemplateView }) && <Callout type={CalloutType.Information} title={"Permission required"}>
                        You can't see custom installed step templates because you don't have <ExternalLink href="UserRoles">{Permission.ActionTemplateView}</ExternalLink> permission.
                    </Callout>}
                    {this.state.categoriesLookup && this.state.categoriesLookup.length > 0 && <ol className={styles.categories}>
                        <div>
                            {this.state.categoriesLookup.map(categoryLookup => {
                                if (categoryLookup.templates.length === 0 && categoryLookup.name !== ActionHandlerCategory.Community) {
                                    // For step templates (or bad API data) that may not have any templates installed yet.
                                    return;
                                }
                                const category = this.props.categories.find(c => c.Id === categoryLookup.name);
                                const categoryEnum = categoryLookup.name as ActionHandlerCategory;
                                let isBlurred = isFiltering && flatten(filteredResults.map(x => x.Categories))
                                    .map(x => x.toLowerCase())
                                    .filter(x => x === category.Id.toLowerCase()).length === 0;
                                if (category.Id === ActionHandlerCategory.Community) {
                                    // Special case to cater for the Community category (it filters on a different set of templates).
                                    const filteredCommunityResults = this.props.communityTemplates.filter(at => matchesFilter(at, this.props.filter));
                                    isBlurred = isFiltering && flatten(filteredCommunityResults.map(x => x.Categories))
                                        .map(x => x.toLowerCase())
                                        .filter(x => x === category.Id.toLowerCase()).length === 0;
                                }
                                return <div key={category.Id}>
                                    <ActionTemplateCategory category={categoryEnum}
                                        name={category.Name}
                                        active={this.state.activeCategory && this.state.activeCategory === categoryLookup.name}
                                        isBlurred={isBlurred}
                                        isSelectable={isFiltering}
                                        templates={categoryLookup.templates}
                                        onCategorySelected={() => {
                                            if (this.state.activeCategory && this.state.activeCategory === categoryLookup.name) {
                                                // De-select.
                                                this.showCategoryTemplates(null, null);
                                            } else {
                                                this.showCategoryTemplates(categoryEnum, categoryLookup.templates);
                                            }
                                        }}
                                    />
                                </div>;
                            })}
                        </div>
                    </ol>}
                </Section>
                {!isFiltering && this.state.categoryTemplates && this.state.categoryTemplates.length > 0 && <TransitionAnimation key={this.state.activeCategory}>
                    <Section sectionHeader={`Installed Step Templates (${this.state.categoryTemplates.length})`} />
                    <ActionTemplateCardList>
                        {this.state.categoryTemplates.map(template => this.renderSingle(template))}
                    </ActionTemplateCardList>
                </TransitionAnimation>}
            </React.Fragment>
            {isFiltering && this.renderFilteredTemplates(filteredResults)}
        </div>;
    }

    private renderFilteredTemplates(filteredResults: ActionTemplateSearchResource[]) {
        if (filteredResults.length === 0 && this.props.filter) {
            return <Section sectionHeader={`Installed Step Templates (${filteredResults.length})`}>
                There are no step templates that match your filter criteria.
            </Section>;
        }

        return <TransitionAnimation key="filteredSteps">
            <Section sectionHeader={`Installed Step Templates (${filteredResults.length})`} />
            <ActionTemplateCardList>
                {filteredResults.map(template => this.renderSingle(template))}
            </ActionTemplateCardList>
        </TransitionAnimation>;
    }

    private renderSingle(template: ActionTemplateSearchResource) {
        return <ActionTemplateCard key={template.Type + template.Id}
            template={template}
            primaryAction={<NavigationButton label="Add"
                type={NavigationButtonType.Primary}
                href={this.props.onPostSelectionUrlRequested(template)} />}
            primaryRedirect={this.props.onPostSelectionUrlRequested(template)}
            secondaryAction={template.HasUpdate &&
                <InternalLink size={0.75}
                    to={this.props.onDetailsUrlRequested(template)}>Update available</InternalLink>}
        />;
    }
}

export default InstalledActionTemplateList;