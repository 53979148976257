import store from "../../store";
import { notificationActions, NotificationType } from "../../components/Navbar/notifications.redux";
import {client} from "../../clientInstance";
import * as notificationLocalState from "../../components/Navbar/NotificationPoller/notificationLocalState";

const notificationId = "Notification/ClientVersionOutdated";
const HTTPHeader = "Octopus-Node";
export const serverVersionNotificationCheck = (request: XMLHttpRequest) => {
    const serverInfo = client.tryGetServerInformation();
    const clientVersion = serverInfo ? serverInfo.version : undefined;
    const apiVersion = getApiVersion(request);

    if (!apiVersion) {
        return;
    }

    //TODO: Check if there is really a need to have this on every ajax call? Can this be done at startup once?
    if (apiVersion === clientVersion) {
        notificationLocalState.clearValue(notificationId);
        store.dispatch(notificationActions.dismissNotification(notificationId));
        return;
    }

    const description = `The current web portal is running as version ${clientVersion} however Octopus Server is
    running ${apiVersion}. Refresh this page to ensure you have the latest portal version.`;

    store.dispatch(notificationActions.raiseNotification({
        id: notificationId,
        value: apiVersion,
        type: NotificationType.info,
        description,
        text: "Server version updated",
        links: [{type: "Refresh", label: "Reload Site"}]
    }));
};

function getApiVersion(request: XMLHttpRequest): string {
    let nodeVersion = request.getResponseHeader(HTTPHeader);
    if (!nodeVersion) {
        return;
    }

    nodeVersion = nodeVersion.split("; ").find(v => v.startsWith("version="));
    if (!nodeVersion) {
        return;
    }
    return nodeVersion.substr(8);
}
