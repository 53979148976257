import ActionTemplateSearchResource from "client/resources/actionTemplateSearchResource";

export default function matchesFilter(template: ActionTemplateSearchResource, filter: string) {
    if (!filter) {
        return true;
    }

    return (template.Name && template.Name.toLowerCase().includes(filter)) ||
        (template.Description && template.Description.toLowerCase().includes(filter)) ||
        (template.Keywords && template.Keywords.toLowerCase().includes(filter)) ||
        (template.Categories && template.Categories.length > 0 && template.Categories.filter(x => x.toLowerCase().includes(filter)).length > 0);
}