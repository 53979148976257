import * as React from "react";
import {ActivityStatus} from "client/resources/taskDetailsResource";
import Close from "material-ui/svg-icons/navigation/close";
const styles = require("./style.less");
import * as cn from "classnames";
import {
    success, danger, alert, primary, primaryText, primaryDark, primaryLight, disable, ternaryText, secondaryText
} from "theme/colors";
import {flatten} from "lodash";
import {UniqueActivityElement} from "components/TaskLogLines/TaskLogBlock";
import {ActivityElement, ActivityLogElement, ActivityLogEntryCategory} from "client/resources";
import ArtifactResource from "client/resources/artifactResource";
import {repository} from "../../../../../clientInstance";
import ExternalLink from "../../../../../components/Navigation/ExternalLink/ExternalLink";
import TextWithLinks from "../../../../../components/TextWithLinks/TextWithLinks";
import Callout, { CalloutType } from "components/Callout";

interface TaskSummaryLineProps {
    element: UniqueActivityElement;
    artifacts: ArtifactResource[];
    showFatalsInline?: boolean;
    onLogNavigation(taskLogLineId: string): void;
}

class TaskSummaryLine extends React.PureComponent<TaskSummaryLineProps> {
    render(): false | JSX.Element {
        const element = this.props.element;
        const childrenToShow = ((element.Children as UniqueActivityElement[]) || []).filter(e => e.ShowAtSummaryLevel);

        const highlights = this.getLogsWithCategory(element, childrenToShow.length === 0, ActivityLogEntryCategory.Highlight)
            .map((h, i) => <div key={i}><TextWithLinks message={h.MessageText} allowHtml={false}/></div>);

        const fatals = this.getLogsWithCategory(element, false, ActivityLogEntryCategory.Fatal)
            .map((h, i) => <div key={i}><TextWithLinks message={h.MessageText} allowHtml={false}/></div>);

        const header = this.props.showFatalsInline || childrenToShow.length === 0
            ? <div>
                <div className={styles.taskSummaryLine}>
                    {this.getStatusIcon(element.Status)}&nbsp;
                    <span className={styles.taskSummaryLineText} onClick={() => this.props.onLogNavigation(element.uniqueId)}>{element.Name}</span>
                </div>
                {fatals && fatals.length > 0 && <div className={styles.fatalBlock}>{fatals}</div>}
              </div>
            : fatals && fatals.length > 0
                ? <Callout title="Failed" type={CalloutType.Danger}>
                    {fatals}
                  </Callout>
                : null;

        const childStyle = this.props.showFatalsInline ? styles.taskSummaryChildren : styles.taskSummaryTopLevel;

        const children = childrenToShow.length > 0 &&
            <div className={childStyle}>
                {childrenToShow.map(e => <TaskSummaryLine key={e.uniqueId} element={e} artifacts={this.props.artifacts} onLogNavigation={this.props.onLogNavigation} showFatalsInline={true}/>)}
            </div>;

        return element.Name && <div>
            {header}
            {children}
            {highlights && highlights.length > 0 && <div className={styles.highlightBlock}>{highlights}</div>}
            {this.getArtifacts(childrenToShow.length === 0)}
        </div>;
    }

    private getLogsWithCategory(element: ActivityElement, recurse: boolean, category: ActivityLogEntryCategory): ActivityLogElement[] {
        const highlights = (element.LogElements || [])
            .filter(e => e.Category === category);

        return recurse
            ? highlights.concat(flatten(element.Children.map(c => this.getLogsWithCategory(c, recurse, category))))
            : highlights;
    }

    private getArtifacts(isLeaf: boolean) {
        const element = this.props.element;

        const artifactResources = this.props.artifacts
            .filter(a =>
                a.LogCorrelationId &&
                isLeaf ? a.LogCorrelationId.startsWith(element.Id) : a.LogCorrelationId === element.Id
            );

        const elements = artifactResources.map(a => <div>
            <ExternalLink href={repository.resolve(a.Links["Content"])}
                          showIcon={false}
                          key={a.Id}
                          openInSelf={true}>{a.Filename}</ExternalLink>
            </div>);

        return elements.length > 0 &&
            <div className={styles.artifactsBlock}>
                {elements}
            </div>;
    }

    private getStatusIcon(nodeStatus: ActivityStatus) {
        switch (nodeStatus) {
            case ActivityStatus.Skipped:
                return <em className={cn("fa fa-minus", styles.summaryIcon)} style={{color: secondaryText}}/>;
            case ActivityStatus.SuccessWithWarning:
                return <span style={{position: "relative"}}>
                    <em className={cn("fa fa-check", styles.summaryIcon)} style={{color: success}}/>
                    <span className="status-overlay"
                          style={{color: alert, position: "absolute", bottom: 0, right: "-2px", fontSize: "10px"}}>
                            <em className="fa fa-exclamation-triangle"/>
                            </span>
                        </span>;
            case ActivityStatus.Failed:
                return <em className={cn("fa fa-times", styles.summaryIcon)} style={{color: danger}}/>;
            case ActivityStatus.Running:
                return <em className={cn("fa fa-spinner fa-pulse", styles.summaryIcon)} style={{color: primaryDark}}/>;
            case ActivityStatus.Pending:
                return <em className={cn("fa fa-clock-o", styles.summaryIcon)} style={{color: primaryLight}}/>;
            case ActivityStatus.Success:
                return <em className={cn("fa fa-check", styles.summaryIcon)} style={{color: success}}/>;
            case ActivityStatus.Canceled:
                return <em className={cn("fa fa-times", styles.summaryIcon)} style={{color: primaryText}}/>;
            default:
                return <Close color={primaryText} style={{width: "16px", height: "16px"}}/>;
        }
    }
}

export default TaskSummaryLine;