import { ProjectGroupResource, TriggerActionType, ProjectSettingsMetadata } from "../resources/index";
import BasicRepository from "./basicRepository";
import Client from "../client";
import {
    ProjectResource,
    ChannelResource,
    TriggerResource,
    ReleaseResource,
    ResourceCollection
} from "../resources";

import { uploadLogo } from "./logoUpload";
import { repository } from "clientInstance";

export interface ListProjectsArgs {
    skip?: number;
    take?: number;
    partialName?: string;
    clonedFromProjectId?: string;
}

class ProjectRepository extends BasicRepository<ProjectResource, ProjectResource> {
    constructor(client: Client) {
        super("Projects", client);
    }
    getDeployments(project: ProjectResource) {
        return this.client.get(this.client.getLink("Deployments"), { projects: project.Id });
    }
    getReleases(project: ProjectResource, args?: { skip?: number, take?: number, searchByVersion?: string }): Promise<ResourceCollection<ReleaseResource>> {
        return this.client.get<ResourceCollection<ReleaseResource>>(project.Links["Releases"], args);
    }
    getReleaseByVersion(project: ProjectResource, version: string): Promise<ReleaseResource> {
        return this.client.get(project.Links["Releases"], { version });
    }
    list(args?: ListProjectsArgs): Promise<ResourceCollection<ProjectResource>> {
        return this.client.get(this.client.getLink("Projects"), { ...args });
    }
    listByGroup(projectGroup: ProjectGroupResource): Promise<ResourceCollection<ProjectResource>> {
        return this.client.get(projectGroup.Links["Projects"]);
    }
    getChannels(project: ProjectResource, skip: number = 0, take: number = repository.takeAll): Promise<ResourceCollection<ChannelResource>> {
        return this.client.get<ResourceCollection<ChannelResource>>(project.Links["Channels"], { skip, take });
    }
    getTriggers(project: ProjectResource, skip?: number, take?: number): Promise<ResourceCollection<TriggerResource>> {
        return this.client.get<ResourceCollection<TriggerResource>>(project.Links["Triggers"], { skip, take });
    }
    orderChannels(project: ProjectResource, channelIds: string[]) {
        return this.client.post(project.Links["OrderChannels"], channelIds);
    }
    getPulse(projects: ProjectResource[]) {
        const projectIds = projects.map((p) => {
            return p.Id;
        }).join(",");
        return this.client.get(this.client.getLink("ProjectPulse"), { projectIds });
    }
    getMetadata(project: ProjectResource): Promise<ProjectSettingsMetadata[]> {
        return this.client.get(project.Links["Metadata"], {});
    }
}

export default ProjectRepository;