import DeploymentActionResource from "./deploymentActionResource";
import * as _ from "lodash";

export interface DeploymentActionPackageResource {
    DeploymentAction: string;
    PackageReference: string;
}

export function displayName(pkg: DeploymentActionPackageResource) {
    return !!pkg.PackageReference
        ? `${pkg.DeploymentAction}/${pkg.PackageReference}`
        : pkg.DeploymentAction;
}

export function deploymentActionPackages(actions: DeploymentActionResource[]) {
    return _.chain(actions)
        .flatMap(action => _.map(action.Packages, pkg => ({DeploymentAction: action.Name, PackageReference: pkg.Name})))
        .value();
}

export function deploymentDeployReleaseActionPackages(actions: DeploymentActionResource[]) {
    return _.chain(actions)
        .flatMap(action => _.map(action.Packages, pkg => ({DeploymentAction: action.Name, PackageReference: pkg.Name || null})))
        .value();
}

export function deploymentActionBuiltInFeedPackages(actions: DeploymentActionResource[]) {
    return _.chain(actions)
    .flatMap(action => _.map(action.Packages, pkg => ({DeploymentAction: action.Name, PackageReference: pkg.Name, FeedId: pkg.FeedId})))
    .value()
    .filter(p => p.FeedId === "feeds-builtin")
    .map(p => ({DeploymentAction: p.DeploymentAction, PackageReference: p.PackageReference}));
}
