import Client from "../client";
import ConfigurationRepository from "./configurationRepository";
import {SmtpConfigurationResource, SmtpIsConfiguredResource} from "client/resources";

class SmtpConfigurationRepository extends ConfigurationRepository<SmtpConfigurationResource> {
    constructor(client: Client) {
        super("SmtpConfiguration", client);
    }

    IsConfigured() {
        return this.client.get<SmtpIsConfiguredResource>(this.client.getLink("SmtpIsConfigured"));
    }
}

export default SmtpConfigurationRepository;