import MachineScriptPolicy from "./machineScriptPolicy";

export interface MachineHealthCheckPolicy {
    PowerShellHealthCheckPolicy: MachineScriptPolicy;
    BashHealthCheckPolicy: MachineScriptPolicy;
    HealthCheckInterval: string;
    HealthCheckType: HealthCheckType;
}

export enum HealthCheckType {
    RunScript = "RunScript",
    OnlyConnectivity = "OnlyConnectivity"
}

export default MachineHealthCheckPolicy;