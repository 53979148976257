import * as React from "react";
import BaseComponent from "components/BaseComponent";
import Text from "components/form/Text/Text";
import {Errors} from "components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import Callout, {CalloutType} from "components/Callout/Callout";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";

interface SaveAsDialogState  {
    newName: string;
}

interface SaveAsDialogProps {
    originalName: string;
    onSave(newName: string): void;
}

export default class SaveAsDialog extends BaseComponent<SaveAsDialogProps, SaveAsDialogState> {

    constructor(props: SaveAsDialogProps) {
        super(props);

        this.state = {
            newName: this.createNewName(props.originalName)
        };
    }

    componentWillReceiveProps(nextProps: SaveAsDialogProps) {
        this.setState({
            newName: this.createNewName(nextProps.originalName)
        });
    }

    render() {
        const errors: Errors = null;
        return <SaveDialogLayout onSaveClick={this.onSave}
                                 title={`Copy of ${this.props.originalName}`}
                                 errors={null}
                                 busy={null}>
            <Callout type={CalloutType.Information} title={"Save a copy to keep your changes"}>
                <ExternalLink href="CommunityContributedStepTemplates">Community step templates</ExternalLink> are read-only because
                they are linked to the <ExternalLink href="CommunityContributedStepTemplates">Community Library</ExternalLink>.
            </Callout>
            <Text
                label="Save as"
                value={this.state.newName}
                onChange={(newName) => this.setState({newName})}
            />
        </SaveDialogLayout>;
    }

    private createNewName = (name: string) => {
        return `${name} Copy`;
    }

    private onSave = () => {
        this.props.onSave(this.state.newName);
        return Promise.resolve(true);
    }
}