import * as React from "react";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { ProjectRouteParams } from "../ProjectLayout/ProjectLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { repository } from "clientInstance";
import ActionTemplateSearchResource from "client/resources/actionTemplateSearchResource";
import CommunityActionTemplateList from "components/ActionTemplates/CommunityActionTemplateList";
import InstalledActionTemplateList from "./InstalledActionTemplateList";
import pluginRegistry from "components/Actions/pluginRegistry";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
const styles = require("./style.less");
import { RouteComponentProps } from "react-router";
import routeLinks from "routeLinks";
import { ActionTemplateCategoryResource, ActionHandlerCategory } from "client/resources";
import Section from "components/Section";

interface ActionTemplateSelectorState extends DataBaseComponentState {
    isLoaded: boolean;
    templates: ActionTemplateSearchResource[];
    categories: ActionTemplateCategoryResource[];
    childActionTypes: string[];
    filterText?: string;
    showCommunityTemplates: boolean;
    selectedCategory?: ActionHandlerCategory;
}

type ActionTemplateSelectorProps = RouteComponentProps<ProjectRouteParams & { parentStepId: string }>;

class ActionTemplateSelector extends DataBaseComponent<ActionTemplateSelectorProps, ActionTemplateSelectorState> {

    constructor(props: ActionTemplateSelectorProps) {
        super(props);

        this.state = {
            isLoaded: false,
            templates: [],
            categories: [],
            childActionTypes: [],
            showCommunityTemplates: false,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const categoriesPromise = repository.ActionTemplates.categories();
            const templatesPromise = repository.ActionTemplates.search();
            const childActionTypes = pluginRegistry.getAllActions()
                .filter(action => action.canBeChild)
                .map(action => action.actionType);
            this.setState({
                isLoaded: true,
                categories: await categoriesPromise,
                templates: await templatesPromise,
                childActionTypes,
            });
        });
    }

    render() {
        const communityTemplates = this.state.templates.filter(at => !at.IsInstalled);
        return <PaperLayout title="Choose Step Template"
            breadcrumbTitle={"Process"}
            breadcrumbPath={routeLinks.project(this.props.match.params.projectSlug).process.root}
            busy={this.state.busy}
            errors={this.state.errors}
            fullWidth={true}
            flatStyle={true}>
            {this.state.isLoaded && <div className={styles.container}>
                <Section>
                    <FilterSearchBox
                        hintText="Filter by name, category or description..."
                        onChange={(value) => this.setState({ filterText: value.toLowerCase() })}
                        fullWidth={true}
                        containerClassName={styles.filterSearchBoxContainer}
                        autoFocus={true}
                        debounceDelay={500}
                    />
                </Section>
                <InstalledActionTemplateList categories={this.state.categories}
                    templates={this.installedTemplates()}
                    communityTemplates={communityTemplates}
                    onDetailsUrlRequested={this.getDetailsUrl}
                    onPostSelectionUrlRequested={this.getNewStepUrl}
                    filter={this.state.filterText}
                    onCategorySelected={async (category: ActionHandlerCategory) => {
                        this.setState({ selectedCategory: category });
                    }}
                />
                {this.shouldShowCommunityTemplateList(this.state.selectedCategory)
                    && <CommunityActionTemplateList templates={communityTemplates}
                        filter={this.state.filterText
                            ? this.state.filterText
                            : (this.state.selectedCategory
                                && this.state.selectedCategory !== ActionHandlerCategory.Community
                                ? this.state.selectedCategory.toLowerCase()
                                : null)}
                        isFilterFromCategory={!!this.state.selectedCategory}
                        installationActionName="Install and add"
                        onDetailsUrlRequested={this.getDetailsUrl}
                        onPostSelectionUrlRequested={this.getNewStepUrl}
                    />}
            </div>}
        </PaperLayout>;
    }

    private shouldShowCommunityTemplateList(category: ActionHandlerCategory): boolean {
        if (this.state.filterText) {
            return true;
        }
        return category
            && category !== ActionHandlerCategory.BuiltInStep
            && category !== ActionHandlerCategory.StepTemplate
            && category !== ActionHandlerCategory.Other;
    }

    private installedTemplates(): ActionTemplateSearchResource[] {
        const installed = this.state.templates.filter(at => at.IsInstalled);
        const parentStepId = this.props.match.params.parentStepId;

        if (!parentStepId) {
            return installed;
        }

        return installed.filter(at => this.state.childActionTypes.includes(at.Type));
    }

    private getDetailsUrl = (template: ActionTemplateSearchResource): string => {
        return routeLinks.library.stepTemplates.communityTemplate(template, this.props.match.params.projectSlug).root;
    }

    private getNewStepUrl = (template: { Type: string, Id: string }): string => {
        return routeLinks.project(this.props.match.params.projectSlug).process
            .stepNew(template.Type, this.props.match.params.parentStepId, template.Id);
    }
}

export default ActionTemplateSelector;