import * as React from "react";
import { repository } from "clientInstance";
import { DataBaseComponentState } from "components/DataBaseComponent";
import { DataBaseComponent } from "components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import { required, Text, Note } from "components/form";
import {
    EnvironmentResource,
    Permission
} from "client/resources";

interface AddEnvironmentsDialogContentProps {
    throwErrors?: boolean;
    saveDone?(environment: EnvironmentResource): any;
}

interface AddEnvironmentsDialogContentState extends DataBaseComponentState {
    name: string;
    description: string;
    useGuidedFailure: boolean;
    newEnvironmentId: string;
}

export default class AddEnvironmentsDialogContent extends DataBaseComponent<AddEnvironmentsDialogContentProps, AddEnvironmentsDialogContentState> {
    constructor(props: AddEnvironmentsDialogContentProps) {
        super(props);
        this.state = {
            name: "",
            description: "",
            useGuidedFailure: false,
            newEnvironmentId: ""
        };
    }

    async save() {
        return this.doBusyTask(async () => {
            const result = await repository.Environments.create({
                Id: null,
                Name: this.state.name,
                Description: this.state.description,
                UseGuidedFailure: this.state.useGuidedFailure,
                AllowDynamicInfrastructure: false,
                SortOrder: -1, // API automatically assigns this value if <= 0.
                Links: null,
                ExtensionSettings: null
            }, {});
            this.setState({
                newEnvironmentId: result.Id
            });
            await this.props.saveDone(result);
        });
    }

    render() {
        return <SaveDialogLayout title={"Add Environment"}
            busy={this.state.busy}
            errors={this.state.errors}
            savePermission={{ permission: Permission.EnvironmentCreate, environment: "*" }}
            onSaveClick={() => this.save()}>
            <Text
                label="New environment name"
                value={this.state.name}
                onChange={this.handleNameChange}
                autoFocus={true}
                validate={required("Please enter an environment name")}
            />
            <Note>
                Examples include: <a href="#" onClick={(e) => this.setEnvironmentName(e, "Development")}>Development</a>,
                <a href="#" onClick={(e) => this.setEnvironmentName(e, "Test")}> Test</a> and
                <a href="#" onClick={(e) => this.setEnvironmentName(e, "Production")}> Production</a>
            </Note>
        </SaveDialogLayout>;
    }

    private setEnvironmentName(e: any, name: string) {
        e.preventDefault();
        this.setState({ name });
    }

    private handleNameChange = (value: string) => {
        this.setState({ name: value });
    }
}
