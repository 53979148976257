import * as React from "react";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { Section } from "components/Section/Section";
import EventListing from "components/EventListing/EventListing";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import {repository} from "clientInstance";
import {session} from "clientInstance";
import {Permission} from "client/resources/permission";
import {ResourceCollection, EventResource} from "client/resources";
import {Callout, CalloutType} from "components/Callout/Callout";

interface UserRecentActivityProps {
    userId: string;
}

interface UserRecentActivityState extends DataBaseComponentState {
    events: ResourceCollection<EventResource>;
}

export default class UserRecentActivity extends DataBaseComponent<UserRecentActivityProps, UserRecentActivityState> {
    constructor(props: UserRecentActivityProps) {
        super(props);
        this.state = ({
            events: null,
        });
    }

    currentUserId(): string {
        return session && session.currentUser ? session.currentUser.Id : null;
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const events = await repository.Events.list({users: [this.props.userId || this.currentUserId()]});
            this.setState({
                events
            });
        });
    }

    render() {
        return <PaperLayout title="My Recent Activity"
            busy={this.state.busy}
            errors={this.state.errors}>
            <PermissionCheck permission={Permission.EventView} wildcard={true} alternate={
                <Callout type={CalloutType.Information} title={"Permission required"}>
                    The {Permission.EventView} permission is required to view recent activity
                </Callout>}>
                <Section>
                    {this.state.events && <EventListing
                        data={this.state.events}
                        regarding={[this.props.userId]}
                        noDataMessage={"There's no activity to show"}
                    />}
                </Section>
            </PermissionCheck>
        </PaperLayout>;
    }
}