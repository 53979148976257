import BasicRepository from "./basicRepository";
import Client from "../client";
import {
    EnvironmentResource,
    EnvironmentsSummaryResource,
    DeploymentTargetResource,
    ResourceCollection,
    VariablesScopedToEnvironmentResponse
} from "client/resources";
import { EnvironmentSettingsMetadata } from "client/resources/extensionSettingsValues";

class EnvironmentRepository extends BasicRepository<EnvironmentResource, EnvironmentResource> {
    constructor(client: Client) {
        super("Environments", client);
    }
    sort(order: string[]) {
        return this.client.put(this.client.getLink("EnvironmentSortOrder"), order);
    }
    variablesScopedOnlyToThisEnvironment(environment: EnvironmentResource): Promise<VariablesScopedToEnvironmentResponse> {
        return this.client.get<VariablesScopedToEnvironmentResponse>(environment.Links["SinglyScopedVariableDetails"]);
    }
    machines(environment: EnvironmentResource, args?: Partial<EnvironmentMachinesArgs>): Promise<ResourceCollection<DeploymentTargetResource>> {
        return this.client.get<ResourceCollection<DeploymentTargetResource>>(environment.Links["Machines"], args);
    }
    summary(args?: Partial<EnvironmentSummaryArgs>) {
        return this.client.get<EnvironmentsSummaryResource>(this.client.getLink("EnvironmentsSummary"), args);
    }
    getMetadata(environment: EnvironmentResource): Promise<EnvironmentSettingsMetadata[]> {
        return this.client.get(environment.Links["Metadata"], {});
    }
}

export type EnvironmentMachinesArgs = {
    skip: number,
    take: number,
    partialName: string,
    roles: string,
    isDisabled: boolean,
    healthStatuses: string,
    commStyles: string,
    tenantIds: string,
    tenantTags: string,
};

export type EnvironmentSummaryArgs = {
    ids: string,
    partialName: string,
    machinePartialName: string,
    roles: string,
    isDisabled: boolean,
    healthStatuses: string,
    commStyles: string,
    tenantIds: string,
    tenantTags: string,
    hideEmptyEnvironments: boolean,
};

export default EnvironmentRepository;