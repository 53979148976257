import { Resource } from "./resource";
import { ReferenceDataItem } from "./referenceDataItem";
import { Form } from "./form";
import { WorkItemLink } from "./packageResource";
import { ReleaseChanges } from ".";
export class MachineDeploymentPreview extends Resource {
    HasLatestCalamari: boolean;
    Name: string;
}

export class DeploymentTemplateStep {
    ActionId: string;
    ActionName: string;
    ActionNumber: string;
    Roles: string[];
    Machines: MachineDeploymentPreview[];
    CanBeSkipped: boolean;
    IsDisabled: boolean;
    HasNoApplicableMachines: boolean;
    UnavailableMachines: ReferenceDataItem[];
    ExcludedMachines: ReferenceDataItem[];
}

export class DeploymentPreviewResource extends Resource {
    StepsToExecute: DeploymentTemplateStep[];
    Form: Form;
    UseGuidedFailureModeByDefault: boolean;
    ReleaseNotes: ReleaseChanges[];
}