import * as React from "react";
import { repository } from "clientInstance";
import { LibraryLayout } from "../LibraryLayout/LibraryLayout";
import { PackageFromBuiltInFeedResource } from "client/resources";
import PaperLayout from "components/PaperLayout";
import ByteSizeFormatter from "utils/ByteSizeFormatter";
import { NavigationButton, NavigationButtonType } from "components/Button";
import { OverflowMenu } from "components/Menu";
import InternalRedirect from "components/Navigation/InternalRedirect";
import Markdown from "components/Markdown";
import DownloadIcon from "material-ui/svg-icons/file/file-download";
import ActionList from "components/ActionList/ActionList";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import {
    DataTable,
    DataTableBody,
    DataTableRow,
    DataTableRowColumn,
    DataTableRowHeaderColumn
} from "components/DataTable";
import { Section } from "components/Section/Section";
import { RouteComponentProps } from "react-router";
import routeLinks from "../../../../routeLinks";
import { Permission } from "client/resources";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import { client } from "../../../../clientInstance";
import PackageMetadataTable from "components/PackageMetadata/PackageMetadataTable";
import { FormSectionHeading } from "components/form";
import moment = require("moment");
import ToolTip from "components/ToolTip";
import DateFormatter from "utils/DateFormatter";

interface PackageDetailState extends DataBaseComponentState {
    package?: PackageFromBuiltInFeedResource;
    deleted?: boolean;
}

export default class PackageDetail extends DataBaseComponent<RouteComponentProps<{ id: string }>, PackageDetailState> {
    private id: string;

    constructor(props: RouteComponentProps<{ id: string }>) {
        super(props);
        this.id = this.props.match.params.id;
        this.state = {};
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            await this.load();
        });
    }

    async load() {
        const pkg = await repository.Packages.get(this.id, { includeNotes: true });
        this.setState({ package: pkg });
    }

    render() {
        const pkg = this.state.package;

        const title = this.state.package &&
            pkg.PackageId + " " + pkg.Version;

        const downloadUploadActions = pkg &&
            <PermissionCheck permission={Permission.BuiltInFeedDownload} project="*">
                <NavigationButton
                    type={NavigationButtonType.Secondary}
                    label="Upload another package"
                    href={routeLinks.library.builtInRepository.root}
                />
                <NavigationButton
                    type={NavigationButtonType.Primary}
                    label="Download"
                    icon={<DownloadIcon />}
                    href={client.resolve(pkg.Links["Raw"])}
                    external={true}
                />
            </PermissionCheck>;

        const actions = [
            downloadUploadActions,
            <OverflowMenu menuItems={[
                OverflowMenu.deleteItemDefault(
                    title,
                    this.handleDeleteConfirm,
                    { permission: Permission.BuiltInFeedAdminister, project: "*" },
                    "Deleting packages will permanently remove files from disk")
            ]} />
        ];

        return <LibraryLayout {...this.props}>
            <PaperLayout title={title}
                breadcrumbTitle={pkg && pkg.PackageId}
                breadcrumbPath={routeLinks.library.builtInRepository.versions(pkg && pkg.PackageId)}
                sectionControl={<ActionList actions={actions} />}
                busy={this.state.busy}
                errors={this.state.errors}>
                {this.state.deleted && <InternalRedirect to={routeLinks.library.builtInRepository.root} />}
                {pkg && <Section>
                    <p>
                        The information below is from the specification embedded in
                        the <strong>{`${pkg.PackageId}.${pkg.Version}${pkg.FileExtension}`}</strong> file.
                    </p>
                </Section>}
                {pkg && <DataTable>
                    <DataTableBody>
                        <DataTableRow>
                            <DataTableRowHeaderColumn>ID</DataTableRowHeaderColumn>
                            <DataTableRowColumn>{pkg.PackageId}</DataTableRowColumn>
                        </DataTableRow>
                        <DataTableRow>
                            <DataTableRowHeaderColumn>Version</DataTableRowHeaderColumn>
                            <DataTableRowColumn>{pkg.Version}</DataTableRowColumn>
                        </DataTableRow>
                        <DataTableRow>
                            <DataTableRowHeaderColumn>Published</DataTableRowHeaderColumn>
                            <DataTableRowColumn><ToolTip content={DateFormatter.dateToLongFormat(pkg.Published)}>{DateFormatter.dateToShortFormat(pkg.Published)}</ToolTip></DataTableRowColumn>
                        </DataTableRow>
                        {pkg.Title &&
                            <DataTableRow>
                                <DataTableRowHeaderColumn>Title</DataTableRowHeaderColumn>
                                <DataTableRowColumn>{pkg.Title}</DataTableRowColumn>
                            </DataTableRow>}
                        {pkg.Summary &&
                            <DataTableRow>
                                <DataTableRowHeaderColumn>Summary</DataTableRowHeaderColumn>
                                <DataTableRowColumn>{pkg.Summary}</DataTableRowColumn>
                            </DataTableRow>}
                        {pkg.Description &&
                            <DataTableRow>
                                <DataTableRowHeaderColumn>Description</DataTableRowHeaderColumn>
                                <DataTableRowColumn>{pkg.Description}</DataTableRowColumn>
                            </DataTableRow>}
                        {pkg.ReleaseNotes &&
                            <DataTableRow>
                                <DataTableRowHeaderColumn>Release Notes</DataTableRowHeaderColumn>
                                <DataTableRowColumn><Markdown markup={pkg.ReleaseNotes} /></DataTableRowColumn>
                            </DataTableRow>}
                        {pkg.FileExtension &&
                            <DataTableRow>
                                <DataTableRowHeaderColumn>File Extension</DataTableRowHeaderColumn>
                                <DataTableRowColumn>{pkg.FileExtension}</DataTableRowColumn>
                            </DataTableRow>}
                        <DataTableRow>
                            <DataTableRowHeaderColumn>Size</DataTableRowHeaderColumn>
                            <DataTableRowColumn>{ByteSizeFormatter(pkg.PackageSizeBytes)}</DataTableRowColumn>
                        </DataTableRow>
                        <DataTableRow>
                            <DataTableRowHeaderColumn>SHA1</DataTableRowHeaderColumn>
                            <DataTableRowColumn>{pkg.Hash}</DataTableRowColumn>
                        </DataTableRow>
                    </DataTableBody>
                </DataTable>}
                {pkg && pkg.PackageMetadata && <>
                    <FormSectionHeading title="Metadata" />
                    <PackageMetadataTable data={pkg.PackageMetadata} />
                </>}
            </PaperLayout>
        </LibraryLayout>;
    }

    private handleDeleteConfirm = async () => {
        await repository.Packages.deleteMany([this.id]);
        this.setState({ deleted: true });
        return true;
    }
}