import * as React from "react";
import {FeedTypeSelect} from "components/FeedTypeSelect/FeedTypeSelect";
import {RouteComponentProps} from "react-router";
import LibraryLayout from "../LibraryLayout/LibraryLayout";
import {ActionButton, ActionButtonType} from "components/Button";
import {repository} from "clientInstance";
import {isEqual, cloneDeep} from "lodash";
import FormBaseComponent, {OptionalFormBaseComponentState} from "components/FormBaseComponent";
import FormPaperLayout from "components/FormPaperLayout";
import { ExternalFeedResource, FeedType} from "client/resources/feedResource";
import {
    ExpandableFormSection,
    Summary,
    Text,
    Note,
    required,
} from "components/form";
import OverflowMenu from "components/Menu/OverflowMenu";
import StringHelper from "utils/StringHelper";
import routeLinks from "routeLinks";
import Permission from "client/resources/permission";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import InternalRedirect from "components/Navigation/InternalRedirect/InternalRedirect";
import feedTypeRegistry from "./FeedTypes";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";

export interface ExternalFeedProps extends RouteComponentProps<ExternalFeedRouteParams> {
    create?: boolean;
}

export interface ExternalFeedRouteParams {
    feedId: string;
}

interface ExternalFeedState extends OptionalFormBaseComponentState<ExternalFeedResource> {
    deleted: boolean;
    newId: string;
    test: boolean;
}

export default class ExternalFeed extends FormBaseComponent<ExternalFeedProps, ExternalFeedState, ExternalFeedResource> {
    constructor(props: ExternalFeedProps) {
        super(props);
        this.state = {
            deleted: false,
            newId: null,
            test: false
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            let feed: ExternalFeedResource = null;
            if (this.props.create) {
                feed = {
                    Id: null as string,
                    FeedType: FeedType.Nuget,
                    DownloadAttempts: 5,
                    DownloadRetryBackoffSeconds: 10,
                    EnhancedMode: false,
                    Name: null,
                    FeedUri: null,
                    Links: null
                };
            } else {
                feed = (await repository.Feeds.get(this.props.match.params.feedId) as ExternalFeedResource);
            }

            this.setState({
                model: feed,
                cleanModel: cloneDeep(feed)
            });
        });
    }

    isDirty() {
        return !isEqual(this.state.model, this.state.cleanModel);
    }

    handleFeedTypeChange = (feedType: FeedType) => {
        let feedUri = this.state.model.FeedUri;
        if (!feedUri) {
            const registration = feedTypeRegistry.getRegistration(feedType);
            if (registration && registration.uriDefault) {
                feedUri = registration.uriDefault;
            }
        }

        if (feedType !== FeedType.OctopusProject && feedType !== FeedType.BuiltIn) {
            this.setModelState({ FeedType: feedType, FeedUri: feedUri });
        }
    }

    render() {
        const isDirty = this.isDirty();

        const title = this.props.create
            ? "Create Feed"
            : this.state.model
                ? this.state.model.Name
                : StringHelper.ellipsis;

        const overFlowActions = [];
        if (!this.props.create && !!this.state.model) {
            overFlowActions.push(OverflowMenu.deleteItemDefault("feed", this.handleDeleteConfirm, {permission: [Permission.FeedEdit]}));
            overFlowActions.push([OverflowMenu.navItem("Audit Trail",
                routeLinks.configuration.eventsRegardingAny([this.state.model.Id]), null, {
                    permission: Permission.EventView,
                    wildcard: true
                })]);
        }

        const saveText: string = this.state.newId
            ? "Feed created"
            : "Feed details updated";

        const saveAndTestButton = <PermissionCheck permission={[Permission.FeedEdit]}>
            <ActionButton
                type={ActionButtonType.Secondary}
                label={(isDirty || !this.state.model || !this.state.model.Id) ? "Save and Test" : "Test"}
                busyLabel={isDirty ? "Saving..." : "Testing..."}
                onClick={() => this.handleTestClick()}
                disabled={!this.saveAndTestButtonIsEnabled()}
            />
        </PermissionCheck>;

        return <LibraryLayout {...this.props}>
            <FormPaperLayout
                title={title}
                breadcrumbTitle={"External Feeds"}
                breadcrumbPath={routeLinks.library.feeds}
                busy={this.state.busy}
                errors={this.state.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                onSaveClick={() => this.handleSaveClick()}
                savePermission={{permission: [Permission.FeedEdit]}}
                saveText={saveText}
                secondaryAction={saveAndTestButton}
                expandAllOnMount={this.props.create}
                overFlowActions={overFlowActions}>
                {this.state.deleted && <InternalRedirect to={routeLinks.library.feeds}/>}
                {this.state.newId && <InternalRedirect to={routeLinks.library.feed(this.state.newId).root}/>}
                {this.state.test && <InternalRedirect
                    to={routeLinks.library.feed(this.state.newId ? this.state.newId : this.state.model.Id).test} push={true}/>}
                {this.state.model && <TransitionAnimation>
                    {!this.props.create &&
                    <ExpandableFormSection
                        errorKey="ID"
                        title="ID"
                        summary={Summary.summary(this.state.model.Id)}
                        help="This is the identity of the feed which can be used in variable bindings. It will never change, even if you rename the feed.">
                        <Text value={this.state.model.Id}
                              label="ID"
                              disabled={true}
                              onChange={x => undefined}/>
                    </ExpandableFormSection>}

                    <ExpandableFormSection errorKey="FeedType"
                                           title="Feed Type"
                                           focusOnExpandAll={this.props.create}
                                           summary={Summary.summary(feedTypeRegistry.getRegistration(this.state.model.FeedType).text)}
                                           help="Select the type of the feed.">
                        <FeedTypeSelect disabled={!this.props.create}
                                        value={this.state.model.FeedType}
                                        onChange={this.handleFeedTypeChange}/>
                    </ExpandableFormSection>

                    <ExpandableFormSection
                        errorKey="Name"
                        title="Name"
                        focusOnExpandAll={!this.props.create}
                        summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your feed")}
                        help="Enter a name for the external feed.">
                        <Text
                            value={this.state.model.Name}
                            onChange={Name => this.setModelState({Name})}
                            label="Feed name"
                            validate={required("Please enter a feed name")}
                            error={this.getFieldError("Name")}
                            autoFocus={true} />
                        <Note>
                            A short, memorable, unique name for this feed. Example: ACME Builds.
                        </Note>
                    </ExpandableFormSection>
                    {this.renderFeedSpecificSection()}
                </TransitionAnimation>}
            </FormPaperLayout>
        </LibraryLayout>;
    }

    private saveAndTestButtonIsEnabled() {
        if (this.state.busy) {
            return false;
        }
        if ((this.state.model && this.state.model.Id) || this.state.newId || this.isDirty()) {
            return true;
        }
        return false;
    }

    private async handleTestClick() {
        if (this.isDirty()) {
            await this.handleSaveClick(true);
        } else {
            this.setState({test: true});
        }
    }

    private handleSaveClick = async (redirectToTest?: boolean) => {
        await this.doBusyTask(async () => {
            const isNew = this.state.model.Id == null;
            const result = (await repository.Feeds.save(this.state.model)) as ExternalFeedResource;
            this.setState({
                model: result,
                cleanModel: cloneDeep(result),
                newId: isNew ? result.Id : null,
                test: redirectToTest,
            });
        });
    }

    private handleDeleteConfirm = async () => {
        const result = await repository.Feeds.del(this.state.model);
        this.setState(state => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true
            };
        });
        return true;
    }

    private renderFeedSpecificSection = (): JSX.Element => {
        const feed = this.state.model;

        const feedRegistraion = feedTypeRegistry.getRegistration(feed.FeedType);
        if (feedRegistraion == null) {
            return null;
        }

        return <React.Fragment>
            {feedRegistraion.hasUri && <ExpandableFormSection
                errorKey="FeedUri"
                title="URL"
                summary={this.state.model.FeedUri ? Summary.summary(this.state.model.FeedUri) : Summary.placeholder("Please enter a url for your feed")}
                help="Provide the location of the feed.">
                <Text value={this.state.model.FeedUri}
                      onChange={FeedUri => this.setModelState({FeedUri})}
                      label="Feed url"
                      validate={required("Please enter a feed url")}
                      error={this.getFieldError("FeedUri")}/>
                <Note>{feedRegistraion.uriNotes}</Note>
            </ExpandableFormSection>}
            <feedRegistraion.edit
                doBusyTask={this.doBusyTask}
                busy={this.state.busy}
                feed={feed}
                onChange={(f: ExternalFeedResource) => this.setState(prev => ({model: {...prev.model, ...f}}))}
                getFieldError={this.getFieldError}/>
        </React.Fragment>;
    }
}
