import * as React from "react";
import {Permission} from "client/resources";
import EditBuiltInRepositoryRetention from "./EditBuiltInRepositoryRetention";
import {SFC} from "react";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import {Callout, CalloutType} from "components/Callout/Callout";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import { BuiltInFeedResource } from "client/resources/feedResource";

interface BuiltInRepositoryRetentionProps {
    config: BuiltInFeedResource;

    afterConfigurationUpdated(config: BuiltInFeedResource): void;
}

const BuiltInRepositoryRetention: SFC<BuiltInRepositoryRetentionProps> = props => {

    const getSummary = () => {
        if (props.config && props.config.DeleteUnreleasedPackagesAfterDays) {
            return <div>
                <p>Packages not used in a release will be automatically deleted from the
                    repository after <strong>{props.config.DeleteUnreleasedPackagesAfterDays}</strong> days.</p>
                <p>Packages used in a release will not be deleted unless the release is deleted.</p>
            </div>;
        } else {
            return <p>Packages stored in the repository will be <strong>retained indefinitely</strong></p>;
        }
    };

    return <div>
        <h4>Repository Retention</h4>
        <PermissionCheck permission={Permission.ConfigureServer}
                         alternate={<Callout type={CalloutType.Information} title={"Permission required"}>
                             The {Permission.ConfigureServer} permission is required to view and change the repository
                             retention settings
                         </Callout>}>
            <div>
                {getSummary()}
                <OpenDialogButton label="Change">
                    <EditBuiltInRepositoryRetention afterConfigurationUpdated={props.afterConfigurationUpdated}/>
                </OpenDialogButton>
            </div>
        </PermissionCheck>
    </div>;
};

BuiltInRepositoryRetention.displayName = "BuiltInRepositoryRetention";

export default BuiltInRepositoryRetention;