import BasicRepository from "./basicRepository";
import Client from "../client";
import {
    WorkerPoolResource,
    WorkerPoolsSummaryResource,
    ResourceCollection,
    WorkerMachineResource,
} from "client/resources";

class WorkerPoolRepository extends BasicRepository<WorkerPoolResource, WorkerPoolResource> {
    constructor(client: Client) {
        super("WorkerPools", client);
    }
    machines(workerPool: WorkerPoolResource, args?: Partial<WorkerPoolsMachinesArgs>): Promise<ResourceCollection<WorkerMachineResource>> {
        return this.client.get<ResourceCollection<WorkerMachineResource>>(workerPool.Links["Workers"], args);
    }
    summary(args?: Partial<WorkerPoolsSummaryArgs>) {
        return this.client.get<WorkerPoolsSummaryResource>(this.client.getLink("WorkerPoolsSummary"), args);
    }
    sort(order: string[]) {
        return this.client.put(this.client.getLink("WorkerPoolsSortOrder"), order);
    }
}

export type WorkerPoolsMachinesArgs = {
    skip: number,
    take: number,
    partialName: string,
    isDisabled: boolean,
    healthStatuses: string,
    commStyles: string,
};

export type WorkerPoolsSummaryArgs = {
    ids: string,
    partialName: string,
    machinePartialName: string,
    isDisabled: boolean,
    healthStatuses: string,
    commStyles: string,
    hideEmptyWorkerPools: boolean,
};

export default WorkerPoolRepository;