import {Resource} from "client/resources/resource";
import {ActionTemplateParameterResource} from "client/resources/actionTemplateParameterResource";
import ActionProperties from "client/resources/actionProperties";
import {PackageReference} from "./packageReference";
import { NamedResource } from ".";

export interface ActionTemplateResource extends Resource {
    Name: string;
    Description: string;
    ActionType: string;
    Version: number;
    CommunityActionTemplateId: string;
    Properties: ActionProperties;
    Parameters: ActionTemplateParameterResource[];
    Packages: PackageReference[];
}

export interface ActionTemplateUsageResource extends Resource {
    ActionTemplateId: string;
    DeploymentProcessId: string;
    ActionId: string;
    ActionName: string;
    StepId: string;
    StepName: string;
    ProjectId: string;
    ProjectName: string;
    ProjectSlug: string;
    Version: string;
}

export interface ActionUpdateResultResource {
    Id: string;
    Outcome: ActionUpdateOutcome;
    NamesOfNewParametersMissingDefaultValue: string[];
    ManualMergeRequiredReasonsByPropertyName: {};
    RemovedPackageUsages: ActionUpdateRemovedPackageUsage[];
}

export enum ActionUpdateOutcome {
    Success = "Success",
    ManualMergeRequired = "ManualMergeRequired",
    DefaultParamterValueMissing = "DefaultParamterValueMissing",
    RemovedPackageInUse = "RemovedPackageInUse"
}

export interface ActionUpdateRemovedPackageUsage {
    PackageReference: string;
    UsedBy: ActionUpdatePackageUsedBy;
    UsedById: string;
    UsedByName: string;
}

export enum ActionUpdatePackageUsedBy {
    ProjectVersionStrategy = "ProjectVersionStrategy",
    ProjectReleaseCreationStrategy = "ProjectReleaseCreationStrategy",
    ChannelRule = "ChannelRule"
}

export interface ActionTemplateCategoryResource extends NamedResource {
    DisplayOrder: number;
}

export enum ActionHandlerCategory {
    Script = "Script",
    Package = "Package",
    Azure = "Azure",
    Aws = "Aws",
    Certificate = "Certificate",
    Docker = "Docker",
    JavaAppServer = "JavaAppServer",
    Kubernetes = "Kubernetes",
    Terraform = "Terraform",
    WindowsServer = "WindowsServer",
    Other = "Other",
    BuiltInStep = "BuiltInStep",
    Community = "Community",
    CommunitySubCategory = "CommunitySubCategory",
    StepTemplate = "StepTemplate",
}