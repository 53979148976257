import isBound from "../../../../../components/form/BoundField/isBound";
import * as React from "react";
import { repository } from "clientInstance";
import FormPaperLayout from "../../../../../components/FormPaperLayout/FormPaperLayout";
import OverflowMenu from "../../../../../components/Menu/OverflowMenu";
import { Permission } from "../../../../../client/resources/permission";
import { ReleaseResource } from "client/resources/releaseResource";
import { ReleaseTemplateResource } from "client/resources/releaseTemplateResource";
import { Callout, CalloutType } from "../../../../../components/Callout/Callout";
import Select from "../../../../../components/form/Select/Select";
import { ExpandableFormSection, Summary, Note } from "components/form";
import { default as FormBaseComponent, OptionalFormBaseComponentState } from "../../../../../components/FormBaseComponent/FormBaseComponent";
import { ProjectResource } from "../../../../../client/resources/projectResource";
import { ResourceCollection } from "../../../../../client/resources/resourceCollection";
import { ChannelResource } from "../../../../../client/resources/channelResource";
import { DeploymentProcessResource } from "../../../../../client/resources/deploymentProcessResource";
import Text from "../../../../../components/form/Text/Text";
import { required } from "components/form/Validators";
import MarkdownEditor from "../../../../../components/form/MarkdownEditor/MarkdownEditor";
import Markdown from "../../../../../components/Markdown/index";
import ExternalLink from "../../../../../components/Navigation/ExternalLink/ExternalLink";
import ToolTip from "../../../../../components/ToolTip/index";
import RadioButton from "../../../../../components/form/RadioButton/RadioButton";
import { DataTable, DataTableBody, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "components/DataTable";
import RadioButtonGroup from "../../../../../components/form/RadioButton/RadioButtonGroup";
import * as _ from "lodash";
import { cloneDeep, Dictionary, flatten, groupBy, isEqual, keys } from "lodash";
import { RouteComponentProps } from "react-router";
import InternalLink from "../../../../../components/Navigation/InternalLink/InternalLink";
import OpenDialogButton from "../../../../../components/Dialog/OpenDialogButton";
import { ActionButtonType } from "components/Button/ActionButton";
import MoreInfo from "../../../../../components/form/Sections/MoreInfo";
import Checkbox from "../../../../../components/form/Checkbox/Checkbox";
import { ProjectRouteParams } from "../../ProjectLayout/ProjectLayout";
import * as URI from "urijs";
import routeLinks from "../../../../../routeLinks";
import { packageRowClass } from "uiTestClasses";
import { PackageEditInfo, VersionType } from "areas/projects/components/Releases/packageModel";
import PackageListDialogContent from "../PackageListDialog/PackageListDialogContent";
import FeedResource, { FeedType } from "client/resources/feedResource";
import MissingProcessStepsMessage from "../MissingProcessStepsMessage";
import * as cn from "classnames";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { ControlExpanders, ControlExpandersProps } from "components/ControlExpanders/ControlExpanders";
import { CardFill } from "components/form/Sections/ExpandableFormSection";
import DebounceValue from "components/DebounceValue/DebounceValue";
import { ResourcesById } from "client/repositories/basicRepository";
import InternalRedirect from "../../../../../components/Navigation/InternalRedirect/InternalRedirect";
const styles = require("./style.less");
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import { PackageReferenceNamesMatch } from "../../../../../client/resources";

const versionExpanderKey = "version";

interface ReleaseModel {
    packages: PackageEditInfo[];
    channels: ResourceCollection<ChannelResource>;
    release: ReleaseResource;
}

interface ReleaseState extends OptionalFormBaseComponentState<ReleaseModel> {
    project: ProjectResource;
    originalVersion: string;
    originalChannelId: string;
    deploymentProc: DeploymentProcessResource;
    template: ReleaseTemplateResource;
    violatedPackages: string[];
    seeVersionExample: boolean;
    isNew: boolean;
    redirect: boolean;
    deleted: boolean;
    defaultCheckModel: ReleaseModel;
    feeds: ResourcesById<FeedResource>;
    hasInitialModelUpdateCompleted: boolean; // To stop the user being able to interact with the release version input before we've finished loading version rules.
}

const DebounceText = DebounceValue(Text);
type channelFilters = { versionRange?: string, preReleaseTag?: string };
type EditProps = RouteComponentProps<ProjectRouteParams & { channelId: string, releaseVersion: string }> & ControlExpandersProps;
class Edit extends FormBaseComponent<EditProps, ReleaseState, ReleaseModel> {
    releaseVersion: any = null;
    channelId: string = null;
    textField: any = null;

    constructor(props: EditProps) {
        super(props);
        this.releaseVersion = this.props && this.props.match && this.props.match.params ? this.props.match.params.releaseVersion : null;
        this.channelId = this.extractChannelId();
        this.state = {
            project: null,
            originalVersion: null,
            originalChannelId: null,
            deploymentProc: null,
            template: null,
            violatedPackages: [],
            seeVersionExample: false,
            isNew: true,
            redirect: false,
            deleted: false,
            defaultCheckModel: null,
            feeds: null,
            hasInitialModelUpdateCompleted: false,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = await repository.Projects.get(this.props.match.params.projectSlug);
            const channels = await repository.Projects.getChannels(project);

            let release = null;
            let originalVersion = null;
            let originalChannelId = null;
            let deploymentProcPromise = null;
            let isNew = true;
            let cleanModel: any = null;
            if (this.releaseVersion) {
                release = await repository.Projects.getReleaseByVersion(project, this.releaseVersion);
                originalVersion = release.Version;
                originalChannelId = release.ChannelId;
                isNew = false;
                deploymentProcPromise = this.loadDeploymentProcess(release.ProjectDeploymentProcessSnapshotId);
            } else {
                const defaultChannel = channels.Items.find(x => x.IsDefault);
                release = {
                    ProjectId: project.Id,
                    ChannelId: this.channelId ? this.channelId : defaultChannel ? defaultChannel.Id : channels.Items[0].Id,
                };
                deploymentProcPromise = this.loadDeploymentProcess(project.DeploymentProcessId);
                cleanModel = {
                    channelId: null,
                    version: null,
                    packages: [],
                    releaseNotes: null,
                    channels: [],
                    release: null,
                    options: null,
                };
            }

            const model = this.buildModel(release as ReleaseResource, [], channels);
            const [feeds, deploymentProc] = await Promise.all([
                repository.Feeds.allById(),
                deploymentProcPromise
            ]);

            if (isNew) {
                model.release.ReleaseNotes = project.ReleaseNotesTemplate || null;
            }
            await this.loadTemplate(model, deploymentProc);
            this.setState({
                project,
                originalVersion,
                originalChannelId,
                deploymentProc,
                model,
                cleanModel: cleanModel ? cleanModel : cloneDeep(model),
                defaultCheckModel: cloneDeep(model),
                isNew,
                feeds,
                hasInitialModelUpdateCompleted: true,
            });
        });
    }

    // This code relies on setState a LOT, and we're running into timing issues between function calls ... so
    // this is an attempt to make this more predictable without having to nest everything in a setState callback.
    // Not sure if we should encourage this sort of pattern in other areas.
    setStateAsync(state: ReleaseState) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    render() {
        const projectRoutes = routeLinks.project(this.props.match.params.projectSlug);
        if (this.state.redirect) {
            return <InternalRedirect to={projectRoutes.release(this.state.model.release.Version).root} push={true} />;
        }
        if (this.state.deleted) {
            return <InternalRedirect to={projectRoutes.releases} push={true} />;
        }

        const overFlowActions = !this.state.isNew && !!this.state.model && !!this.state.model.release
            ? [
                OverflowMenu.deleteItemDefault("release",
                    this.handleDeleteConfirm,
                    {
                        permission: Permission.ReleaseDelete,
                        project: this.state.project && this.state.project.Id,
                        tenant: "*",
                    },
                    "The release and any of its deployments will be permanently deleted and they will disappear from all dashboards."),
                [OverflowMenu.navItem("Audit Trail",
                    routeLinks.configuration.eventsRegardingAny([this.state.model.release.Id]), null, {
                        permission: Permission.EventView,
                        wildcard: true
                    })]
            ]
            : [];

        let title = "Release";
        if (this.state.project) {
            title = this.state.isNew ? "Create release for " + this.state.project.Name
                : (this.state.model && this.state.model.release ? "Edit release " + this.state.model.release.Version : "Edit release");
        }
        if (this.state.deploymentProc && this.state.deploymentProc.Steps.length === 0) {
            return <PaperLayout
                busy={this.state.busy}
                errors={this.state.errors}
                title={title}
                breadcrumbTitle={"Releases"}
                breadcrumbPath={this.state.project ? routeLinks.project(this.state.project).releases : null}>
                <MissingProcessStepsMessage project={this.state.project} />
            </PaperLayout>;
        }

        return <FormPaperLayout
            busy={this.state.busy}
            errors={this.state.errors}
            title={title}
            breadcrumbTitle={"Releases"}
            breadcrumbPath={this.state.project ? routeLinks.project(this.state.project).releases : null}
            model={this.state.model}
            cleanModel={this.state.cleanModel}
            disableDirtyFormChecking={this.state.isNew && this.disableDirtyFormCheck()}
            savePermission={{
                permission: this.state.isNew ? Permission.ReleaseCreate : Permission.ReleaseEdit,
                project: this.state.project && this.state.project.Id,
                tenant: "*",
            }}
            onSaveClick={this.handleSaveClick}
            overFlowActions={overFlowActions}
            saveText="Release saved">
            {this.state.originalChannelId && this.state.originalChannelId !== this.state.model.release.ChannelId &&
                <Callout title="Note" type={CalloutType.Danger}>
                    <p>Changing the channel of this release is only allowed where all the steps
                    previously applicable
                    with respect to any channel filters) will still be applicable with the new
                    channel.</p>
                    <p>Please make sure you are aware which steps are now active as you may be
                    unable to reverse this change.</p>
                </Callout>}
            {this.state.model && this.state.hasInitialModelUpdateCompleted && <TransitionAnimation>
                {this.state.model.channels && this.state.model.channels.Items && this.state.model.channels.Items.length > 1 &&
                    <ExpandableFormSection
                        errorKey="channel"
                        title="Channel"
                        focusOnExpandAll
                        summary={this.state.model.release.ChannelId
                            ? Summary.summary(this.state.model.channels.Items.find(x => x.Id === this.state.model.release.ChannelId).Name)
                            : Summary.placeholder("Please select a channel")}
                        help={this.state.model.channels.Items.length > 1
                            ? "Select a channel for this release"
                            : this.state.model.channels.Items[0].Name}>
                        {this.state.model.channels && this.state.model.channels.Items.length > 1 &&
                            <Select value={this.state.model.release.ChannelId}
                                onChange={async (channelId) => await this.onChannelChanged(channelId)}
                                items={this.state.model.channels.Items.map(c => ({
                                    text: c.Name,
                                    value: c.Id
                                }))}
                                label="Channel" />}
                    </ExpandableFormSection>}
                <ExpandableFormSection
                    errorKey={versionExpanderKey}
                    title="Version"
                    summary={this.state.model.release.Version ? Summary.summary(this.state.model.release.Version) : Summary.placeholder("Please enter a version")}
                    help="Enter a unique version number for this release with at least two parts.">
                    <Text
                        value={this.state.model.release.Version}
                        onChange={(version) => this.setChildState2("model", "release", { Version: version })}
                        label="Version"
                        validate={required("Please enter a version number")}
                    />
                    {this.state.project && this.state.template && this.state.template.LastReleaseVersion && !this.state.originalVersion &&
                        <div>
                            Most recent release: <InternalLink
                                to={routeLinks.project(this.state.project).release(this.state.template.LastReleaseVersion).root}>
                                {this.state.template.LastReleaseVersion}</InternalLink>
                        </div>}
                    <MoreInfo content={
                        <div>
                            <p>You can use standard version numbers with two, three or four components:</p>
                            <ul>
                                <li><code>2.3</code></li>
                                <li><code>2.3.16</code></li>
                                <li><code>2.3.16.384</code></li>
                            </ul>
                            <p>You can also include <ExternalLink href="SemVer">semantic version</ExternalLink> tags:</p>
                            <ul>
                                <li><code>2.3.16-beta</code></li>
                                <li><code>2.3.16.384-pre-release</code></li>
                            </ul>
                            <p>You can also use the letter <code>i</code> to increment part of the last release, for example:</p>
                            <ul>
                                <li><code>1.i</code></li>
                                <li><code>1.15.i</code></li>
                                <li><code>2.i.i</code></li>
                            </ul>
                        </div>}
                    />
                </ExpandableFormSection>
                {this.state.model.packages && this.state.model.packages.length > 0 &&
                    <ExpandableFormSection
                        errorKey="packages"
                        title="Packages"
                        fillCardWidth={CardFill.FillAll}
                        summary={this.packagesSummary()}
                        help="Select package(s) for this release">
                        <div className={styles.packageTableContainer}>
                            <DataTable className={styles.packageTable}>
                                <DataTableHeader>
                                    <DataTableRow>
                                        <DataTableHeaderColumn>
                                            <div className={styles.actionName}>Step</div>
                                            Package
                                            </DataTableHeaderColumn>
                                        <DataTableHeaderColumn>
                                            <ToolTip key="latest"
                                                content="The most recent package that we could find in the package feed that matches channel rules">
                                                <ExternalLink href="LatestPackage">Latest</ExternalLink>
                                                {this.state.model.packages && this.state.model.packages.length > 1 && <React.Fragment>
                                                    <br />
                                                    <Note>
                                                        <a href="#" onClick={(e: any) => this.setAllPackageVersionsTo(e, VersionType.latest, null, false)}>Select all</a>
                                                    </Note>
                                                </React.Fragment>}
                                            </ToolTip>
                                        </DataTableHeaderColumn>
                                        {this.state.template && this.state.template.LastReleaseVersion && !this.state.originalVersion &&
                                            <DataTableHeaderColumn>
                                                <ToolTip key="last"
                                                    content={"The version selected for release " + this.state.template.LastReleaseVersion}>Last
                                                </ToolTip>
                                                {this.state.model.packages && this.state.model.packages.length > 1 && <React.Fragment>
                                                    <br />
                                                    <Note>
                                                        <a href="#" onClick={(e: any) => this.setAllPackageVersionsTo(e, VersionType.last, null, false)}>Select all</a>
                                                    </Note>
                                                </React.Fragment>}
                                            </DataTableHeaderColumn>}
                                        <DataTableHeaderColumn>
                                            Specific
                                            {this.state.model.packages && this.state.model.packages.length > 1 &&
                                                this.state.model.release && this.state.model.release.Version && <React.Fragment>
                                                    <br />
                                                    <Note>
                                                        <a href="#" onClick={(e: any) => this.setAllPackageVersionsTo(e, VersionType.specific, this.state.model.release.Version, true)}>Select current release version</a>
                                                    </Note>
                                                </React.Fragment>}
                                        </DataTableHeaderColumn>
                                    </DataTableRow>
                                </DataTableHeader>
                                <DataTableBody>
                                    {this.state.model.packages && this.state.model.packages.map(pack =>
                                        <DataTableRow key={pack.ActionName} className={packageRowClass}>
                                            <DataTableRowColumn className={cn(styles.packageTableRowColumn, styles.packageColumn)}>
                                                <div className={styles.actionName}>
                                                    {pack.ActionName}{!!pack.PackageReferenceName && <span>/{pack.PackageReferenceName}</span>}
                                                </div>
                                                <ToolTip key="packageId" content={pack.ProjectName ? pack.ProjectName : pack.PackageId + " from " + pack.FeedName}>
                                                    {pack.ProjectName ? pack.ProjectName : pack.PackageId}
                                                </ToolTip>
                                            </DataTableRowColumn>
                                            <DataTableRowColumn className={cn(styles.packageTableRowColumn, styles.latestColumn)}>
                                                {this.buildRadioButton(pack, pack.LatestVersion, VersionType.latest, this.state.model)}
                                            </DataTableRowColumn>
                                            {this.state.template && this.state.template.LastReleaseVersion && !this.state.originalVersion &&
                                                <DataTableRowColumn className={cn(styles.packageTableRowColumn, styles.lastColumn)}>
                                                    <div className={styles.specificVersionDiv}>
                                                        {pack.LastReleaseVersion &&
                                                            <div>
                                                                {!pack.IsLastReleaseVersionValid
                                                                    ? <ToolTip
                                                                        content="Package version does not satisfy channel rules">
                                                                        {this.buildRadioButton(pack, pack.LastReleaseVersion, VersionType.last, this.state.model)}
                                                                    </ToolTip>
                                                                    : <div>{this.buildRadioButton(pack, pack.LastReleaseVersion, VersionType.last, this.state.model)}</div>}
                                                            </div>
                                                        }</div>
                                                </DataTableRowColumn>}
                                            <DataTableRowColumn className={cn(styles.packageTableRowColumn, styles.specificColumn)}>
                                                <div className={styles.specificVersionDiv}>
                                                    <div className={styles.inlineDiv}>
                                                        {this.buildRadioButton(pack, pack.SpecificVersion, VersionType.specific, this.state.model)}
                                                    </div>
                                                    <div className={styles.inlineDiv}>
                                                        <div className={styles.editVersionArea}>
                                                            <DebounceText id={pack.ActionName}
                                                                debounceDelay={500}
                                                                className={styles.versionTextbox}
                                                                hintText="Enter a version"
                                                                value={pack.SpecificVersion}
                                                                onChange={async (version) => {
                                                                    await this.specificVersionSelected(this.state.model, pack, version);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className={styles.inlineDiv}>
                                                        {this.packageVersionsButton(pack)}
                                                    </div>
                                                </div>
                                            </DataTableRowColumn>
                                        </DataTableRow>)}
                                </DataTableBody>
                            </DataTable>
                        </div>
                        {this.state.violatedPackages && this.state.violatedPackages.length > 0 &&
                            <Callout type={CalloutType.Warning} title="Version satisfaction">
                                <Checkbox label="Force Version Selection"
                                    value={this.state.model.release.IgnoreChannelRules}
                                    onChange={(ignoreChannelRules) => {
                                        this.setChildState2("model", "release", { IgnoreChannelRules: ignoreChannelRules });
                                    }} />
                                <p>You have selected a package version that violates the version rules specified by the selected channel.
                                    You must explicitly check the box above to force this selection and ignore
                                    the channel rules for the release to be created.</p>
                            </Callout>}
                    </ExpandableFormSection>}
                <ExpandableFormSection
                    errorKey="notes"
                    title="Release Notes"
                    summary={this.state.model.release.ReleaseNotes ?
                        Summary.summary("Release notes have been provided") : Summary.placeholder("No release notes provided")}
                    help={this.buildReleaseNoteHelpInfo()}>
                    <MarkdownEditor
                        value={this.state.model.release.ReleaseNotes}
                        label="Release notes"
                        onChange={releaseNotes => this.setChildState2("model", "release", { ReleaseNotes: releaseNotes })}
                    />
                </ExpandableFormSection>
            </TransitionAnimation>}
        </FormPaperLayout>;
    }

    private setAllPackageVersionsTo = (e: any, versionType: VersionType, specificVersion: string, includeConfirmation: boolean) => {
        e.preventDefault();
        if (includeConfirmation
            && !confirm(`This will set all packages to version ${specificVersion}. Are you sure this version exists for all the packages?`)) {
            return;
        }

        const model = this.state.model;
        const release = model.release;
        release.SelectedPackages = [];
        for (const selection of this.state.model.packages) {
            selection.VersionType = versionType;
            selection.SpecificVersion = specificVersion;
            release.SelectedPackages.push({
                ActionName: selection.ActionName,
                Version: specificVersion,
                PackageReferenceName: selection.PackageReferenceName
            });
        }

        this.setState({ model });
    }

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const model = this.state.model;
            const release = model.release;
            release.SelectedPackages = [];
            for (const selection of this.state.model.packages) {
                let selectedVersion = "";
                if (selection.VersionType === VersionType.latest) {
                    selectedVersion = selection.LatestVersion;
                } else if (selection.VersionType === VersionType.last) {
                    selectedVersion = selection.LastReleaseVersion;
                } else if (selection.VersionType === VersionType.specific) {
                    selectedVersion = selection.SpecificVersion;
                }

                release.SelectedPackages.push({
                    ActionName: selection.ActionName,
                    Version: selectedVersion,
                    PackageReferenceName: selection.PackageReferenceName
                });
            }

            const newRelease = await save(release);
            const newModel = this.buildModel(newRelease, this.state.model.packages, this.state.model.channels);
            this.setState({
                model: newModel,
                cleanModel: cloneDeep(newModel),
                redirect: true,
            });
        });

        function save(release: ReleaseResource) {
            if (release.Links) {
                return repository.Releases.modify(release);
            }
            return repository.Releases.create(release);
        }
    }

    private packageVersionsButton = (pack: PackageEditInfo) => {
        const openDialog = (disabled: boolean) => <OpenDialogButton type={ActionButtonType.Secondary}
            wideDialog={true}
            disabled={disabled}
            label="Select Version">
            <PackageListDialogContent
                pack={pack}
                onVersionSelected={async (version) => {
                    await this.specificVersionSelected(this.state.model, pack, version);
                }}
                channelFilters={this.getChannelFilters(this.state.model, pack.ActionName, pack.PackageReferenceName)}
            />
        </OpenDialogButton>;
        if (this.state.feeds && this.state.feeds[pack.FeedId]) {
            return openDialog(false);
        }
        return <ToolTip content="No feed available. Package step may be using a variable as feed.">{openDialog(true)}</ToolTip>;
    }

    private packagesSummary = () => {
        if (!this.state.model.packages || this.state.model.packages.length === 0) {
            return Summary.placeholder("No package is included");
        }

        const packageVersions = this.state.model.packages.map(p => this.getPackageInfoVersion(p));

        if (packageVersions.length === 1) {
            return Summary.summary(packageVersions[0]
                ? "1 package included, at version " + packageVersions[0]
                : <span>1 package included, <strong>no version specified</strong></span>);
        }

        const firstVersion = packageVersions.find(p => !!p);
        const noneHaveVersion = !firstVersion;
        const allOnSameVersion = firstVersion && packageVersions.every(p => p === firstVersion);
        const numberWithNoVersion = packageVersions.filter(p => !p).length;
        const packagesIncluded = packageVersions.length + " packages included";
        const noVersionSummary = numberWithNoVersion
            ? <span>, <strong>{numberWithNoVersion} {numberWithNoVersion === 1 ? "has" : "have"} no version selected</strong></span>
            : <span />;
        const versionSummary = allOnSameVersion
            ? ", all at version " + firstVersion
            : noneHaveVersion ? "" : ", with a mix of versions";
        return Summary.summary(<span>{packagesIncluded}{versionSummary}{noVersionSummary}</span>);
    }

    private getPackageInfoVersion(info: PackageEditInfo): string {
        return info.VersionType === VersionType.specific
            ? info.SpecificVersion
            : info.LatestVersion;
    }

    private disableDirtyFormCheck = () => {
        // don't want "dirty" to be triggered by the version being auto populated or channel from route param
        return this.state.cleanModel
            && isEqual(this.state.defaultCheckModel, this.state.model);
    }

    private buildRadioButton(pack: PackageEditInfo, version: string, type: VersionType, model: ReleaseModel) {
        if (!pack.IsResolvable && type === VersionType.latest) {
            return <div />;
        }
        return <RadioButtonGroup className={styles.radioButtonContainer}
            value={type}
            onChange={async (item) => {
                await this.packageVersionChanged(
                    model,
                    pack,
                    version,
                    type);
            }}>
            <RadioButton
                className={styles.myRadioButton}
                value={pack.VersionType}
                label={type === VersionType.specific ? "" : version} />
        </RadioButtonGroup>;
    }

    private buildReleaseNoteHelpInfo = () => {
        const helpInfo = "Enter a summary of what has changed in this release, such as which features were added and which bugs were fixed. " +
            "These notes will be shown on the release page. You can edit these notes later.";
        return helpInfo;
    }

    private specificVersionSelected = async (model: ReleaseModel, pack: PackageEditInfo, version: string) => {
        pack.SpecificVersion = version;
        await this.packageVersionChanged(model, pack, version, VersionType.specific);
    }

    private handleDeleteConfirm = async (): Promise<boolean> => {
        if (!this.state.isNew) {
            await repository.Releases.del(this.state.model.release);
            this.setState(state => {
                return {
                    model: null,
                    cleanModel: null,
                    deleted: true,
                };
            });
            return true;
        } else {
            return false;
        }
    }

    private loadDeploymentProcess = async (processId: string) => {
        const deploymentProcess = await repository.DeploymentProcesses.get(processId);
        return deploymentProcess;
    }

    private async loadTemplate(model: ReleaseModel, deploymentProc: DeploymentProcessResource) {
        const template = await repository.DeploymentProcesses.getTemplate(deploymentProc, model.release.ChannelId, null);
        if (!model.release.Id) {
            if (template.NextVersionIncrement) {
                model.release.Version = template.NextVersionIncrement;
            }
        }

        const existingSelections: { [actionName: string]: string } = {};
        if (model.release.SelectedPackages) {
            for (const p of model.release.SelectedPackages) {
                existingSelections[p.ActionName] = p.Version;
            }
        }

        const selectionByFeed: { [feedId: string]: PackageEditInfo[] } = {};
        const packageSelections = [];
        for (const p of template.Packages) {
            const specificVersion = existingSelections[p.ActionName] ? existingSelections[p.ActionName] : "";
            const isResolvable = p.IsResolvable;
            const lastReleaseVersion = p.VersionSelectedLastRelease;
            const selection: PackageEditInfo = {
                ActionName: p.ActionName,
                PackageReferenceName: p.PackageReferenceName,
                PackageId: p.PackageId,
                ProjectName: p.ProjectName,
                FeedId: p.FeedId,
                FeedName: p.FeedName,
                LatestVersion: "",
                SpecificVersion: specificVersion,
                IsResolvable: isResolvable,
                LastReleaseVersion: lastReleaseVersion,
                VersionType: specificVersion ? VersionType.specific : (isResolvable ? VersionType.latest : (lastReleaseVersion ? VersionType.last : VersionType.specific)),
                IsLastReleaseVersionValid: !isBound(p.FeedId),
            };
            packageSelections.push(selection);

            if (selection.IsResolvable) {
                if (!selectionByFeed[selection.FeedId]) {
                    selectionByFeed[selection.FeedId] = [];
                }
                selectionByFeed[selection.FeedId].push(selection);
            }
        }

        await this.setStateAsync({ ...this.state, template });
        await this.loadVersions(model, selectionByFeed); // This function depends on template being in state.

        model.packages = packageSelections;
        this.setState({ model });
        if (!model.release.Version) {
            this.props.setExpanderState(versionExpanderKey, true);
        }
    }

    private setVersionSatisfaction = async (model: ReleaseModel, pkg: PackageEditInfo, version: string, versionType: VersionType, feedType: FeedType) => {
        const violatedPackages = this.state.violatedPackages.slice();
        const filters = this.getChannelFilters(model, pkg.ActionName, pkg.PackageReferenceName);
        if (versionType) {
            pkg.VersionType = versionType;
        }
        await this.doBusyTask(async () => {
            const result = await repository.Channels.ruleTest(version, {
                versionRange: filters.versionRange,
                preReleaseTag: filters.preReleaseTag,
                feedType,
            });

            const isSelectedVersionValid = result.Errors.indexOf("Invalid Version Number") !== -1 || (result.SatisfiesVersionRange && result.SatisfiesPreReleaseTag);
            const position = violatedPackages.indexOf(pkg.ActionName);

            if (isSelectedVersionValid && position !== -1) {
                violatedPackages.splice(position, 1);
            } else if (!isSelectedVersionValid && position === -1) {
                violatedPackages.push(pkg.ActionName);
            }

            this.setState({ violatedPackages });
        });
    }

    private loadVersions(model: ReleaseModel, selectionsByFeed: Dictionary<PackageEditInfo[]>): Promise<boolean> {

        async function checkForRuleSatisfaction(selection: PackageEditInfo, filters: { versionRange?: string, preReleaseTag?: string }, feedType: FeedType) {
            if (selection.LastReleaseVersion) {
                const result = await repository.Channels.ruleTest(selection.LastReleaseVersion, {
                    versionRange: filters.versionRange,
                    preReleaseTag: filters.preReleaseTag,
                    feedType
                });
                selection.IsLastReleaseVersionValid = result.SatisfiesVersionRange && result.SatisfiesPreReleaseTag;
            } else {
                selection.IsLastReleaseVersionValid = false;
            }
        }

        async function getPackageVersion(self: Edit, feedId: string): Promise<any> {
            const feed = await repository.Feeds.get(feedId);
            const selections = selectionsByFeed[feedId];

            const packageSearchGroups = groupBy(selections
                .map(selection => ({ selection, filter: self.getChannelFilters(model, selection.ActionName, selection.PackageReferenceName) })),
                ({ selection, filter }) => selection.PackageId + JSON.stringify(filter || {}));

            const t = Object.values(packageSearchGroups).map(async sameFilteredPackages => {
                const releases = (await repository.Feeds.searchPackageVersions(feed, sameFilteredPackages[0].selection.PackageId, {
                    ...sameFilteredPackages[0].filter,
                    take: 1
                })).Items;

                return sameFilteredPackages.map(async ({ selection, filter }) => {
                    await checkForRuleSatisfaction(selection, filter, feed.FeedType);
                    if (releases.length === 0) {
                        // no latest version found
                        selection.IsResolvable = false;
                        // Docker feeds may not conform to semver, in which case there will be no valid versions.
                        // However you can manually enter a version like "latest", and this will be shown as the
                        // last version. It is convenient to select that last version rather than default to
                        // the specific version field.
                        selection.VersionType = selection.LastReleaseVersion
                            ? VersionType.last
                            : VersionType.specific;
                        return self.setVersionSatisfaction(model, selection, selection.SpecificVersion, null, feed.FeedType);
                    }

                    const pkg = releases[0];
                    selection.LatestVersion = pkg.Version;
                    if (!model.release.Id) {
                        return self.packageVersionChanged(model, selection, pkg.Version, null);
                    }

                    return self.setVersionSatisfaction(model, selection, selection.SpecificVersion, null, feed.FeedType);
                });
            });
            return await Promise.all(flatten(await Promise.all(t)));
        }

        return this.doBusyTask(async () => {
            return Promise.all(
                keys(selectionsByFeed)
                    .filter(f => !isBound(f))
                    .map(f => getPackageVersion(this, f))
            );
        });
    }

    private packageVersionChanged = async (m: ReleaseModel, pkg: PackageEditInfo, version: string, versionType: VersionType) => {
        const model = { ...m };
        if (this.state.template
            && this.state.template.VersioningPackageStepName
            && this.state.template.VersioningPackageStepName === pkg.ActionName
            && this.state.template.VersioningPackageReferenceName === pkg.PackageReferenceName
            && this.state.isNew) {
            model.release.Version = version;
        }

        if (versionType) {
            pkg.VersionType = versionType;
            if (versionType === VersionType.specific) {
                pkg.SpecificVersion = version;
            }
        }

        if (!isBound(pkg.FeedId) && this.state.feeds) {
            const feed = this.state.feeds[pkg.FeedId];
            if (feed) {
                await this.setVersionSatisfaction(model, pkg, version, versionType, feed.FeedType);
            }
        }

        this.setState({ model });
    }

    private getChannelFilters = (model: ReleaseModel, deploymentActionName: string, packageReferenceName: string): channelFilters => {
        const filters: channelFilters = {};

        if (!model || !model.release.ChannelId) {
            return filters;
        }

        const applicableRules = model.channels.Items
            .find((x) => {
                return x.Id === model.release.ChannelId;
            })
            .Rules
            .find((rule) => {
                return rule.ActionPackages.length === 0 || (rule.ActionPackages.findIndex(x =>
                    x.DeploymentAction === deploymentActionName && PackageReferenceNamesMatch(packageReferenceName, x.PackageReference)) >= 0);
            });
        if (applicableRules && applicableRules.VersionRange) {
            filters.versionRange = applicableRules.VersionRange;
        }

        if (applicableRules && applicableRules.Tag) {
            filters.preReleaseTag = applicableRules.Tag;
        }
        return filters;
    }

    private onChannelChanged = async (channelId: string) => {
        this.state.model.release.ChannelId = channelId;
        await this.doBusyTask(async () => {
            await this.loadTemplate(_.cloneDeep(this.state.model), this.state.deploymentProc);
        });
    }

    private buildModel(release: ReleaseResource, packageSelections: PackageEditInfo[], channels: ResourceCollection<ChannelResource>): ReleaseModel {
        const model: ReleaseModel = {
            packages: packageSelections,
            release,
            channels
        };
        return model;
    }

    private extractChannelId(): string {
        const relativeUrl = new URI(this.props.location.search).search(true);
        return relativeUrl.channelId;
    }
}

export default ControlExpanders(Edit);
