import * as React from "react";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import {
    EnvironmentsSummaryResource,
    TenantResource,
} from "client/resources";
const styles = require("./style.less");
import {LookupTenantChip} from "components/Chips/index";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import {secondaryText} from "theme/colors";
import {isAllowed} from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import routeLinks from "../../../../routeLinks";
import GlobalState from "globalState";
import { connect, MapStateToProps } from "react-redux";

interface TenantsCardProps {
    environmentSummary: EnvironmentsSummaryResource;
    tenants: TenantResource[];
}

interface ConnectedProps {
    isMultiTenancyEnabled: boolean;
}

interface TenantsCardState extends DataBaseComponentState {
    filterText?: string;
}

type Props = TenantsCardProps & ConnectedProps;

class TenantsCard extends DataBaseComponent<Props, TenantsCardState> {
    constructor(props: Props) {
        super(props);
        this.state = ({
        });
    }

    render() {
        if (!this.props.environmentSummary || !this.props.tenants || !this.props.isMultiTenancyEnabled || !isAllowed({
            permission: Permission.TenantView,
            tenant: "*",
        })) {
            return null;
        }

        const tenantSummaries = this.props.environmentSummary.MachineTenantSummaries;
        const tenants = this.props.tenants.filter((item) => this.onFilter(this.state.filterText, item));
        const tenantRows = tenants.map(tenant => {
            const totalMachines = (tenantSummaries && (tenantSummaries as any)[tenant.Id]) || 0;
            return <div className={styles.tenantRowsContainer} key={tenant.Id}>
                <div className={styles.tenantName}>
                    <LookupTenantChip lookupTenants={this.props.tenants} id={tenant.Id}
                        to={routeLinks.infrastructure.machines.filtered({tenantIds: tenant.Id})}
                    />
                </div>
                <div className={styles.tenantMachinesCount}>{totalMachines.toLocaleString()}</div>
            </div>;
        });

        return <div className={styles.card}>
            <div className={styles.cardTitleContainer}>
                <div className={styles.cardTitle}>
                    Tenants <span className={styles.cardCount}>({tenants.length.toLocaleString()})</span>
                </div>
            </div>
            <div className={styles.cardContentContainer}>
                {(this.props.tenants.length > 1 || this.state.filterText) && <div>
                    <FilterSearchBox
                        hintText="Filter..."
                        onChange={(value) => this.setState({ filterText: value.toLowerCase()})}
                        inputClassName={styles.dashboardFilterInputClassName}
                        iconColor={secondaryText}
                        iconStyle={{width: "20px", height: "20px", top: "14px"}}
                    />
                </div>}
                {tenantRows}
            </div>
        </div>;
    }

    private onFilter(filter: string, resource: TenantResource) {
        return !filter || filter.length === 0 || !resource
            || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }
}

const mapStateToProps = (state: GlobalState, props: TenantsCardProps): ConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled
    };
};

export default connect(mapStateToProps)(TenantsCard);