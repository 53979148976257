import * as colors from "./colors";

export const legacyTheme = {
    textField: {
        textColor: colors.primaryText
    },
    paper: {
        textColor: colors.primaryText,
        backgroundColor: colors.white,
    },
    menuItem: {
        hoverColor: colors.hover
    },
    stepper: {
        iconColor: colors.success,
        hoverBackgroundColor: colors.hover,
        inactiveIconColor: colors.secondaryText,
        textColor: colors.primaryText,
        disabledTextColor: colors.secondaryText,
        connectorLineColor: colors.secondaryText,
    },
    palette: {
        primary1Color: colors.primary,
        textColor: colors.primaryText,
        canvasColor: colors.white,
      },
    datePicker: {
        headerColor: colors.primary,
        color: colors.primary,
        selectColor: colors.primary,
    },
    flatButton: {
        primaryTextColor: colors.primary,
    }
};
