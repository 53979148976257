import * as React from "react";
import { WorkItemLink } from "client/resources";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import ExternalLink from "components/Navigation/ExternalLink";

interface WorkItemsProps {
    data: WorkItemLink[];
}

export default class WorkItems extends DataBaseComponent<WorkItemsProps, DataBaseComponentState> {
    constructor(props: WorkItemsProps) {
        super(props);
        this.state = {};
    }

    trimEnd = (value: string): string => {
        return value.endsWith("/") ? value.substring(0, value.length - 1) : value;
    }

    trimStart = (value: string): string => {
        return value.startsWith("/") ? value.substring(1) : value;
    }

    resolveLink = (workItem: WorkItemLink): JSX.Element => {
        if (workItem.LinkUrl) {
            return <div key={workItem.Id}><ExternalLink href={workItem.LinkUrl}>{workItem.Id}</ExternalLink>{(workItem.Id !== workItem.Description ? ` - ${workItem.Description}` : "")}</div>;
        } else {
            return <div key={workItem.Id}>{workItem.Id}{(workItem.Description && workItem.Id !== workItem.Description ? ` - ${workItem.Description}` : "")}</div>;
        }
    }

    render() {
        return this.props.data.map(workItem => this.resolveLink(workItem));
    }
}
