import BasicRepository from "./basicRepository";
import Client from "../client";
import { UserResource, ResourceCollection, ApiKeyResource, LoginCommand, SpaceResource } from "client/resources";
import { repository } from "clientInstance";

class UserRepository extends BasicRepository<UserResource, UserResource> {
    constructor(client: Client) {
        super("Users", client);
    }
    signIn(loginCommand: LoginCommand): Promise<UserResource> {
        return this.client.post<UserResource>(this.client.getLink("SignIn"), loginCommand)
            .then((authenticatedUser) => {
                const antiforgeryToken = this.client.getAntiforgeryToken();
                if (antiforgeryToken) {
                    return authenticatedUser;
                } else {
                    throw new Error("The required anti-forgery cookie is missing. Perhaps your browser " +
                        "or another network device is blocking cookies? " +
                        "See http://g.octopushq.com/CSRF for more details and troubleshooting.");
                }
            });
    }
    signOut() {
        return this.client.post(this.client.getLink("SignOut"), {});
    }
    getCurrent() {
        return this.client.get<UserResource>(this.client.getLink("CurrentUser"));
    }
    getSpaces(user: UserResource) {
        return this.client.get<SpaceResource[]>(user.Links["Spaces"]);
    }
    createApiKey(user: UserResource, purpose: any): Promise<ApiKeyResource> {
        return this.client.post(user.Links["ApiKeys"], { Purpose: purpose });
    }
    listApiKeys(user: UserResource): Promise<ResourceCollection<ApiKeyResource>> {
        return this.client.get(user.Links["ApiKeys"], { take: repository.takeAll });
    }
    revokeApiKey(apiKey: ApiKeyResource) {
        return this.client.del(apiKey.Links["Self"]);
    }
    register(registerCommand: any) {
        return this.client.post(this.client.getLink("Register"), registerCommand);
    }
    getTriggers(user: UserResource) {
        return this.client.get(user.Links["Triggers"]);
    }
}

export default UserRepository;