import * as React from "react";
import * as cn from "classnames";
import Popover from "material-ui/Popover";
import Notification from "material-ui/svg-icons/social/notifications";
import ExternalLink from "../Navigation/ExternalLink";
import InternalLink from "../Navigation/InternalLink";
import Divider from "material-ui/Divider";
import Badge from "material-ui/Badge";
const styles = require("./notifications.less");
const stylesDefault = require("./style.less");
import { danger, white, success } from "theme/colors";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import {values} from "lodash";
import {connect} from "react-redux";
import GlobalState from "../../globalState";
import {
    NotificationItem, NotificationType, NotificationItemLink,
    NotificationItemHrefLink
} from "./notifications.redux";
import * as notificationLocalState from "./NotificationPoller/notificationLocalState";

interface NotificationsMenuState {
    isMenuOpen: boolean;
    notifications: NotificationItem[];
    showNewNotificationsAlert: boolean;
}

interface NotificationsMenuProps {
    notifications: NotificationItem[];
}

class NotificationsMenu extends BaseComponent<NotificationsMenuProps, NotificationsMenuState> {
    private static defaultNotification: NotificationItem = {
        id: null,
        text: "You have no notifications.",
        type: NotificationType.happy,
        description: "Happy deployments!"
    };

    private menuButton: HTMLElement;

    constructor(props: NotificationsMenuProps) {
        super(props);

        this.state = {
            isMenuOpen: false,
            notifications: [NotificationsMenu.defaultNotification],
            showNewNotificationsAlert: false,
        };
    }

    async componentDidMount() {
        this.checkNotificationsAlert(this.props);
    }

    componentWillReceiveProps(newProps: NotificationsMenuProps) {
        if (this.props.notifications !== newProps.notifications) {
            this.checkNotificationsAlert(newProps);
        }
    }

    checkNotificationsAlert = (props: NotificationsMenuProps) => {
        const showNewNotificationsAlert = !!props.notifications
            .find(n => {
                const currentValue = notificationLocalState.getCurrentValue(n.id);
                const notificationHasValue = !!n.value;

                return !currentValue ||
                    !((notificationHasValue && currentValue === n.value) || !notificationHasValue);
            });
        this.setState({showNewNotificationsAlert});
    }

    renderBadge() {
        if (this.state.showNewNotificationsAlert) {
            return <Badge
                style={{padding: 0}}
                badgeContent={""}
                badgeStyle={{
                    backgroundColor: danger,
                    border: "white 2px solid",
                    top: 0,
                    right: 0,
                    width: 12,
                    height: 12
                }}>
                <Notification color={white}/>
            </Badge>;
        }
        return <Badge
            style={{padding: 0}}
            badgeContent={""}
            badgeStyle={{width: 0, height: 0}} >
            <Notification color={white}/>
        </Badge>;
    }

    renderNotification = (notification: NotificationItem, index: number) => {
        return <div key={notification.text} className={styles.container}>
            {index > 0 && <Divider/>}
            <div className={styles.notification}>
                <div className={styles.grow}>
                    <div className={cn(styles.title, {
                        [styles.textHappy]: notification.type === NotificationType.happy,
                        [styles.textInfo]: notification.type === NotificationType.info,
                        [styles.textWarn]: notification.type === NotificationType.warn,
                        [styles.textError]: notification.type === NotificationType.error,
                    })}>{notification.text}</div>
                    <div className={styles.description}>{notification.description}</div>
                    {notification.links && <div className={styles.links}>
                        {notification.links.map((link, i) =>
                            <span key={i}>
                                {i > 0 && " | "}
                                {this.renderLink(link)}
                            </span>
                        )}
                    </div>}
                </div>
                <div className={styles.icon}>{this.displayIcon(notification.type)}</div>
            </div>
        </div>;
    }

    renderLink(link: NotificationItemLink | NotificationItemHrefLink) {
        switch (link.type) {
            case "External": {
                return <ExternalLink href={link.href}>{link.label}</ExternalLink>;
            }
            case "Internal": {
                return <InternalLink to={link.href}>{link.label}</InternalLink>;
            }
            case "Refresh": {
                return <a onClick={() => window.location.reload()} href="#">{link.label}</a>;
            }
            default: {
                return <span>{link.label}</span>;
            }
        }
    }

    render() {
        const notifications = [...this.props.notifications];
        if (notifications.length === 0) {
            notifications.push(NotificationsMenu.defaultNotification);
        }
        return <div>
            <a className={stylesDefault.button}
               onClick={this.handleTouchTap}
               href="#"
               title="Notifications"
               ref={this.setRef}>{this.renderBadge()}</a>
            <Popover
                open={this.state.isMenuOpen}
                anchorEl={this.menuButton}
                onRequestClose={this.handleRequestClose}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                targetOrigin={{ horizontal: "right", vertical: "top" }}>
                {notifications.map(this.renderNotification)}
            </Popover>
        </div>;
    }

    private markNotificationsAsRead = () => {
        this.props.notifications
            .forEach(n => notificationLocalState.setCurrentValue(n.id, n.value));

        this.setState({
            showNewNotificationsAlert: false
        });
    }

    private displayIcon = (type: NotificationType) => {
        switch (type) {
            case NotificationType.happy:
                return <div className={cn("fa fa-smile-o", styles.notificationIcon, styles.bgHappy)} />;
            case NotificationType.info:
                return <div className={cn("fa fa-info-circle", styles.notificationIcon, styles.bgInfo)} />;
            case NotificationType.warn:
                return <div className={cn("fa fa-warning", styles.notificationIcon, styles.bgWarn)} />;
            case NotificationType.error:
                return <div className={cn("fa fa-warning", styles.notificationIcon, styles.bgError)} />;
        }
    }

    private setRef = (el: HTMLElement) => {
        this.menuButton = el;
    }

    private handleTouchTap = (event: any) => {
        event.preventDefault();

        this.markNotificationsAsRead();

        this.setState({
            isMenuOpen: !this.state.isMenuOpen
        });
    }

    private handleRequestClose = () => {
        this.setState({
            isMenuOpen: false,
        });
    }
}

export default connect(
    (state: GlobalState, props) => {
        return {
            notifications: values(state.notifications)
        };
    },
    (dispatch) => {
        return {
        };
    })(NotificationsMenu);