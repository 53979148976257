import * as React from "react";
import { SFC } from "react";
const styles = require("./style.less");

const StepName: SFC<{ name: string, number: string, stepType: string }> = props =>
    <div className={styles.stepTitle}>
        <div className={styles.stepType}>{props.stepType}</div>
        <div>
            <span className={styles.stepNumber}>{props.number}.</span>
            {/* mark.siedle - This is an exception to the usual ellipsis pattern because of the StepDetailsLoader. */}
            <span>{props.name || "Step Details"}</span>
        </div>
    </div>;

StepName.displayName = "StepName";

export default StepName;
