import * as React from "react";
import EventListener, { withOptions } from "react-event-listener";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { Cancelable, throttle } from "lodash";
import WindowHelper from "utils/WindowHelper/WindowHelper";
import { SummaryNode, UnstructuredFormSection, Note } from "components/form";
import { MenuItem } from "components/Menu/OverflowMenu";
import { SpaceResource } from "client/resources";
import { ScopedUserRoleModel } from "../TeamEdit";
import ScopedUserRoleGroupExpander from "../ScopedUserRoleGroupExpander/ScopedUserRoleGroupExpander";
import styles = require("./style.less");

interface ScopedUserRoleExpandersListProps {
    helpElement: JSX.Element;
    listActions: JSX.Element[];
    scopedUserRoles: ScopedUserRoleModel[];
    spaces: SpaceResource[];
    onRow(item: ScopedUserRoleModel, index: number): React.ReactNode;
    onRowSummary(item: SpaceResource): SummaryNode;
    onRowHelp(item: SpaceResource): string;
    onRowOverflowMenuItems(spaceId: string | null): Array<MenuItem | MenuItem[]>;
}

export class ScopedUserRolesExpandersList extends BaseComponent<ScopedUserRoleExpandersListProps, {}> {
    private topActionsMenu: HTMLDivElement;
    private bottomActionsMenu: HTMLDivElement;
    private readonly handleResize: (() => void) & Cancelable;
    private readonly handleScroll: (() => void) & Cancelable;

    constructor(props: ScopedUserRoleExpandersListProps) {
        super(props);
        this.updateButtonsVisibility = this.updateButtonsVisibility.bind(this);
        this.handleResize = throttle(this.updateButtonsVisibility, 100);
        this.handleScroll = throttle(this.updateButtonsVisibility, 50);
    }

    componentDidMount() {
        this.updateButtonsVisibility();
    }

    componentWillUnmount() {
        this.handleResize.cancel();
        this.handleScroll.cancel();
    }

    render() {
        return <React.Fragment>
            <EventListener
                target="window"
                onResize={this.handleResize}
                onScroll={withOptions(this.handleScroll, { passive: true, capture: false })}
            />
            <UnstructuredFormSection>
                <div className={styles.headerBar}>
                    <Note>{this.props.helpElement}</Note>
                    <div ref={el => this.topActionsMenu = el} className={styles.actionsMenu}>{this.props.listActions}</div>
                </div>
            </UnstructuredFormSection>
            <div>
                {this.renderSystemItem()}
                {this.props.spaces
                    .sort((a, b) => a.Name.localeCompare(b.Name))
                    .map(item => this.buildItem(item))
                }
            </div>
            <UnstructuredFormSection>
                {/* We include a bottom section that is ALWAYS visible and toggle visibility of the actions for this component.
                        Do not change to hide/show or things get janky. */}
                <div ref={el => this.bottomActionsMenu = el} style={{ visibility: "hidden" }} className={styles.actionsMenu}>{this.props.listActions}</div>
            </UnstructuredFormSection>
        </React.Fragment>;
    }

    private renderSystemItem() {
        const systemScopedUserRoles = this.props.scopedUserRoles.filter(x => !x.SpaceId);
        if (!systemScopedUserRoles.length) {
            return null;
        }
        return <ScopedUserRoleGroupExpander
            key={"system"}
            errorKey={"system"}
            title={"System"}
            overflowMenuItems={this.props.onRowOverflowMenuItems(null)}
        >
            {systemScopedUserRoles.map((x, i) => this.props.onRow(x, i))}
        </ScopedUserRoleGroupExpander>;
    }

    private buildItem(space: SpaceResource) {
        const scopedUserRolesForSpace = this.props.scopedUserRoles.filter(x => x.SpaceId === space.Id);
        if (scopedUserRolesForSpace.length === 0) {
            return null;
        }
        return <ScopedUserRoleGroupExpander
            key={space.Id}
            errorKey={space.Id}
            title={space.Name === "Default" ? "Default Space" : space.Name}
            overflowMenuItems={this.props.onRowOverflowMenuItems(space.Id)}
        >
            {scopedUserRolesForSpace.map((x, i) => this.props.onRow(x, i))}
        </ScopedUserRoleGroupExpander>;
    }

    private updateButtonsVisibility() {
        if (this.topActionsMenu && this.bottomActionsMenu) {
            let result = "visible";
            if (WindowHelper.isElementInViewport(this.topActionsMenu) && WindowHelper.isElementCenterTouchable(this.topActionsMenu)) {
                result = "hidden";
            } else if (!WindowHelper.isElementInViewport(this.bottomActionsMenu)) {
                // If neither are visible, it might be that we're in a tab that isn't showing
                // so hide the bottom one so we don't show both when we come back.
                result = "hidden";
            }
            this.bottomActionsMenu.style.visibility = result;
        }
    }
}
