import * as React from "react";
import { CommitDetail } from "client/resources";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import ExternalLink from "components/Navigation/ExternalLink";

interface CommitDetailsProps {
    data: CommitDetail[];
}

export default class CommitDetails extends DataBaseComponent<CommitDetailsProps, DataBaseComponentState> {
    constructor(props: CommitDetailsProps) {
        super(props);
        this.state = {};
    }

    trimEnd = (value: string): string => {
        return value.endsWith("/") ? value.substring(0, value.length - 1) : value;
    }

    trimStart = (value: string): string => {
        return value.startsWith("/") ? value.substring(1) : value;
    }

    resolveLink = (commitDetail: CommitDetail): JSX.Element => {
        const shortCommitSha = commitDetail.Id.substring(0, 7);
        if (commitDetail.LinkUrl) {
            return <div key={commitDetail.Id}><ExternalLink href={commitDetail.LinkUrl}>{shortCommitSha}</ExternalLink> - {commitDetail.Comment}</div>;
        } else {
            return <div key={commitDetail.Id}>{shortCommitSha} - {commitDetail.Comment}</div>;
        }
    }

    render() {
        return this.props.data.map(commitDetail => this.resolveLink(commitDetail));
    }
}
