import * as React from "react";
import {SFC} from "react";
import {Section} from "components/Section/Section";
import {NavigationButton, NavigationButtonType} from "components/Button/NavigationButton";
import {ServerStatusResource} from "client/resources/serverStatusResource";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";

interface SystemDiagnosticReportProps {
    serverStatus: ServerStatusResource;
}

const SystemDiagnosticReport: SFC<SystemDiagnosticReportProps> = props => {
    return <Section sectionHeader="System Diagnostics Report">
        <p>
            If you are experiencing problems with your Octopus instance, attaching this report to your support request
            may lead to a quicker resolution.
        </p>
        <p>
            This generated system report may contain information specific to this Octopus Deploy installation, such as
            file paths, deployment target, project, user, or environment details. Every effort is made to mask sensitive
            data such as passwords.
        </p>
        <p>
            If you are concerned about sharing any of this information with the Octopus Deploy Support Team we recommend
            opening the file and sending only extracts relevant to your specific problem.
        </p>
        <PermissionCheck permission={Permission.AdministerSystem}>
            <NavigationButton label="Download System Diagnostics Report"
                href={props.serverStatus.Links["SystemReport"]}
                external={true}
                type={NavigationButtonType.Ternary}
            />
        </PermissionCheck>
    </Section>;
};

SystemDiagnosticReport.displayName = "SystemDiagnosticReport";

export default SystemDiagnosticReport;