import Logo from "components/Logo";
import * as React from "react";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import ProjectLayout from "../ProjectLayout/ProjectLayout";
import FormBaseComponent, {OptionalFormBaseComponentState} from "components/FormBaseComponent/FormBaseComponent";
import { RouteComponentProps, Redirect } from "react-router";
import {DeploymentStepResource, RunCondition, StartTrigger} from "client/resources/deploymentStepResource";
import {StepDetailsParams} from "./StepDetailsLoader";
import Text from "components/form/Text/Text";
import {ExpandableFormSection, Summary, FormSectionHeading} from "components/form";
import {RoleChip} from "components/Chips";
import { cloneDeep } from "lodash";
import ParseHelper from "utils/ParseHelper/ParseHelper";
import {ActionPlugin} from "components/Actions/pluginRegistry";
import RoleMultiSelect from "components/MultiSelect/RoleMultiSelect";
import RadioButtonGroup, {BooleanRadioButtonGroup} from "components/form/RadioButton/RadioButtonGroup";
import RadioButton from "components/form/RadioButton/RadioButton";
import {repository} from "clientInstance";
import {ProjectResource} from "client/resources/projectResource";
import Note from "components/form/Note/Note";
const styles = require("./style.less");
import RunTriggerExpander from "./RunTriggerExpander";
import StartTriggerExpander from "./StartTriggerExpander";
import ActionProperties from "client/resources/actionProperties";
import Permission from "client/resources/permission";
import { projectStepsUpdated } from "../../reducers/projectsArea";
import { connect } from "react-redux";
import { DeploymentProcessRoute } from "./DeploymentProcessRoute";
import routeLinks from "routeLinks";
import MaxParallelism from "./MaxParallelism";
import InternalRedirect from "components/Navigation/InternalRedirect/InternalRedirect";
import StepName from "./StepName";
import { OverflowMenu } from "components/Menu";
import { DeploymentProcessResource } from "client/resources";

const rollingStep = require("./step-rolling.svg");

interface ParentStepDetailsModel {
    name: string;
    props: ActionProperties;
    condition: RunCondition;
    startTrigger: StartTrigger;
}

interface ParentStepDetailsState extends OptionalFormBaseComponentState<ParentStepDetailsModel> {
    redirectTo?: string;
    showWindowSize: boolean;
}

interface ParentStepDetailsProps extends RouteComponentProps<StepDetailsParams> {
    stepNumber: string;
    step: DeploymentStepResource;
    availableRoles: any[];
    project: ProjectResource;
    isFirstStep: boolean;
    deploymentProcess: DeploymentProcessResource;
}

interface DispatchProps {
    onProjectStepsUpdated(numberOfSteps: number): void;
}

type Props = ParentStepDetailsProps & DispatchProps;

class ParentStepDetailsInternal extends FormBaseComponent<Props, ParentStepDetailsState, ParentStepDetailsModel> {
    constructor(props: Props) {
        super(props);

        const step = this.props.step;
        const model = {
            name: step.Name,
            props: step.Properties,
            condition: step.Condition,
            startTrigger: step.StartTrigger
        };
        this.state = {
            model,
            cleanModel: cloneDeep(model),
            showWindowSize: model.props["Octopus.Action.MaxParallelism"]
                ? model.props["Octopus.Action.MaxParallelism"].toString().length > 0
                : false
        };
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} />;
        }

        const processEditPermission = {permission: Permission.ProcessEdit, project: this.props.project.Id, tenant: "*"};
        const actions = [OverflowMenu.deleteItemDefault("parent step", () => this.deleteStep(this.props.step), processEditPermission)];

        return <FormPaperLayout
            title={<StepName name={this.state.model && this.state.model.name} number={this.props.stepNumber} stepType="Parent Step" />}
            titleLogo={<Logo url={rollingStep} />}
            breadcrumbTitle="Process"
            breadcrumbPath={routeLinks.project(this.props.project).process.root}
            busy={this.state.busy}
            errors={this.state.errors}
            model={this.state.model}
            cleanModel={this.state.cleanModel}
            savePermission={{
                permission: Permission.ProcessEdit,
                project: this.props.project && this.props.project.Id,
                tenant: "*"
            }}
            onSaveClick={this.handleSaveClick}
            overFlowActions={actions}
            saveText="Step details updated">

                <ExpandableFormSection
                    isExpandedByDefault={!this.state.model.name}
                    errorKey="Name"
                    title="Step Name"
                    focusOnExpandAll
                    summary={this.state.model.name
                        ? Summary.summary(this.state.model.name)
                        : Summary.placeholder("Please enter a name for your step")}
                    help="A short, memorable, unique name for this step.">
                    <Text
                        value={this.state.model.name}
                        onChange={name => this.setModelState({name})}
                        label="Step name"
                        autoFocus={true}
                    />
                </ExpandableFormSection>

                <ExpandableFormSection
                    isExpandedByDefault={!this.state.model.name}
                    errorKey="Octopus.Action.TargetRoles"
                    title="Execution Plan"
                    summary={this.executionPlanSummary()}
                    help="Where should this step run?">
                    <RoleMultiSelect label="Runs on targets in roles"
                                     onChange={roles => this.setProperties({["Octopus.Action.TargetRoles"]: ParseHelper.encodeCSV(roles)})}
                                     value={ParseHelper.parseCSV(this.state.model.props["Octopus.Action.TargetRoles"] as string)}
                                     validate={roles => roles.length === 0 ? "Please enter one or more roles" : ""}
                                     error={this.getFieldError("Octopus.Action.TargetRoles")}
                                     items={this.props.availableRoles}/>
                    <BooleanRadioButtonGroup
                    onChange={v => {
                            this.setState({showWindowSize: v});
                            this.setProperties({ ["Octopus.Action.MaxParallelism"]: (v ? "1" : "") });
                        }}
                        value={this.state.showWindowSize}>
                        <RadioButton value={false} label="Deploy to all deployment targets in parallel."/>
                        <RadioButton value={true} label="Configure a rolling deployment"/>
                    </BooleanRadioButtonGroup>
                    {this.state.showWindowSize && this.renderWindowSize()}
                </ExpandableFormSection>

                <FormSectionHeading title="Conditions"/>
                <RunTriggerExpander
                    isFirstStep={this.props.isFirstStep}
                    condition={this.state.model.condition}
                    onConditionChange={condition => this.setModelState({condition})}
                    variableExpression={this.state.model.props["Octopus.Action.ConditionVariableExpression"] as string}
                    onVariableExpressionChange={(x) => this.setProperties({["Octopus.Action.ConditionVariableExpression"]: x})}
                    projectId={this.props.project.Id}
                />

                {!this.props.isFirstStep && <StartTriggerExpander
                    startTrigger={this.state.model.startTrigger}
                    onChange={startTrigger => this.setModelState({startTrigger})}/>}
            </FormPaperLayout>;
    }

    private async deleteStep(step: DeploymentStepResource) {
        await this.doBusyTask(async () => {
            const stepIndex = this.props.deploymentProcess.Steps.indexOf(step);
            this.props.deploymentProcess.Steps.splice(stepIndex, 1);

            const result = await repository.DeploymentProcesses.modify(this.props.deploymentProcess);
            if (result) {
                const redirectTo = routeLinks.project(this.props.project).process.root;
                this.setState({ redirectTo });
            }
        });
        return true;
    }

    private renderWindowSize() {
        const maxParallelism = this.state.model.props["Octopus.Action.MaxParallelism"] as string;
        return <MaxParallelism projectId={this.props.project.Id}
            value={maxParallelism}
            onChange={x => this.setProperties({ ["Octopus.Action.MaxParallelism"]: x })} />;
    }

    private setProperties(properties: Partial<ActionProperties>) {
        this.setState(state => {
            return {
                model: {
                    ...state.model,
                    props: {
                        ...state.model.props,
                        ...properties
                    }
                }
            };
        });
    }

    private executionPlanSummary() {
        const summary = [<span>This step will run on</span>];
        const onBehalf = false;

        const roles = ParseHelper.parseCSV(this.state.model.props["Octopus.Action.TargetRoles"] as string);
        const parallelism = this.state.model.props["Octopus.Action.MaxParallelism"];
        if (roles.length > 0) {
            summary.push(onBehalf
                ? <span> on behalf of </span>
                : roles.length > 1
                    ? <span> in roles</span>
                    : <span> in role</span>);
            roles.forEach(r => {
                summary.push(<RoleChip role={r} key={"role-" + r}/>);
            });
        }

        if (parallelism) {
            // tslint:disable-next-line
            summary.push(<span>as <strong>rolling step</strong> that will run {onBehalf ? "on behalf of" : "on"} <strong>{parallelism}</strong> target{parallelism !== "1" ? "s" : ""} at a time</span>);
        } else {
            summary.push(<span>with all targets deployed in <strong>parallel</strong></span>);
        }

        return Summary.summary(React.Children.toArray(summary));
    }

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {

            let deploymentProcess = await repository.DeploymentProcesses.get(this.props.project.DeploymentProcessId);
            const originalStep = deploymentProcess.Steps.find(s => s.Id === this.props.match.params.stepId);

            originalStep.Name = this.state.model.name;
            originalStep.Properties = {...originalStep.Properties, ...this.state.model.props};
            originalStep.Condition = this.state.model.condition;
            originalStep.StartTrigger =  this.state.model.startTrigger;

            deploymentProcess = await repository.DeploymentProcesses.modify(deploymentProcess);
            this.props.onProjectStepsUpdated(deploymentProcess.Steps ? deploymentProcess.Steps.length : null);
            this.reloadThePage();
        });
    }

    private reloadThePage() {
        const currentPath = this.props.location.pathname;
        const reloadKey = this.props.match.params.reloadKey;
        const path = DeploymentProcessRoute.nextStepReloadPath(currentPath, reloadKey);

        this.setState({redirectTo: path});
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        onProjectStepsUpdated: (numberOfSteps: number) => {
            dispatch(projectStepsUpdated(numberOfSteps));
        },
    };
};

const ParentStepDetails = connect<void, DispatchProps, ParentStepDetailsProps>(
    null,
    mapDispatchToProps
)(ParentStepDetailsInternal);

export default ParentStepDetails;
