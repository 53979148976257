import * as React from "react";
import LegacyMuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { MuiThemeProvider } from "@material-ui/core/styles";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import { getTheme, ThemePaletteType, legacyTheme } from "theme";
import { getCurrentTheme } from "./reducers/selectors";
import { connect } from "react-redux";
import GlobalState from "globalState";

interface ThemeProps {
    theme: ThemePaletteType;
}

const Theme: React.StatelessComponent<ThemeProps> = ({theme, children}) => {
    return <LegacyMuiThemeProvider muiTheme={getMuiTheme(legacyTheme)}>
        <MuiThemeProvider theme={getTheme(theme)}>
            {children}
        </MuiThemeProvider>
    </LegacyMuiThemeProvider>;
};

const mapStateToProps = (state: GlobalState) => ({
  theme: getCurrentTheme(state)
});

const ConnectedTheme = connect(mapStateToProps)(Theme);
export default ConnectedTheme;

export { Theme, ConnectedTheme };