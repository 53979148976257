export enum ControlType {
    SingleLineText = "SingleLineText",
    MultiLineText = "MultiLineText",
    Select = "Select",
    Checkbox = "Checkbox",
    Sensitive = "Sensitive",
    StepName = "StepName",
    AzureAccount = "AzureAccount",
    Certificate = "Certificate",
    AmazonWebServicesAccount = "AmazonWebServicesAccount",
    Custom = "Custom"
}