import * as React from "react";
import SvgIconMaterial from "material-ui/SvgIcon";
import {white} from "theme/colors";

// We are using SVG content directly because SvgIconMaterial doesn't allow us to reference svg files directly.

const EnvironmentIcon = (props: any) => <SvgIconMaterial {...props} viewBox="0 0 200 200">
    {/* tslint:disable-next-line */}
    <path d="M14 30v35h172V30zm26 26H23V39h17zM14 83v35h95V83zm26 26H23V92h17zM14 134v35h172v-35zm26 26H23v-17h17z"/>
</SvgIconMaterial>;

const ExcludedEnvironmentIcon = (props: any) => <SvgIconMaterial {...props} viewBox="0 0 200 200">
    {/* tslint:disable-next-line */}
    <path d="M6.713 20.367l7.784-8.206 176.512 167.446-7.783 8.205z"/>
    {/* tslint:disable-next-line */}
    <path d="M88.13 83H14v35h95v-15.2zM40 109H23V92h17zM141.89 134H14v35h164.78zM23 160v-17h17v17zM186 160.27V134h-27.69L186 160.27zM85.58 65H186V30H48.69l36.89 35zM32.27 30H14v35h55.16zM23 56V39h17v17z"/>
</SvgIconMaterial>;

const ProjectIcon = (props: any) => <SvgIconMaterial {...props} viewBox="0 0 200 200">
    {/* tslint:disable-next-line */}
    <path d="M174.33 26H25.67A14.67 14.67 0 0 0 11 40.7v14.36h178V40.7A14.67 14.67 0 0 0 174.33 26zM11 63.09v96.21A14.72 14.72 0 0 0 25.67 174h148.66A14.72 14.72 0 0 0 189 159.3V63.09zm59.12 58.42h-37v-37h37zm93.63 17H87.11a4.06 4.06 0 1 1 0-8.11h76.64a4.06 4.06 0 1 1 0 8.11zM83 111.52a4.06 4.06 0 0 1 4.06-4.06h59.31a4.06 4.06 0 1 1 0 8.11H87.11a4.06 4.06 0 0 1-4.11-4.05zm81.61-18.83h-77.5a4.06 4.06 0 0 1 0-8.11h77.56a4.06 4.06 0 1 1 0 8.11z"/>
</SvgIconMaterial>;

const MachineIcon = (props: any) => <SvgIconMaterial {...props} viewBox="0 0 200 200">
    {/* tslint:disable-next-line */}
    <path d="M119.19 70.52h6.54a27 27 0 0 0-18-18V59a3.87 3.87 0 0 1-3.84 3.84h-7.73A3.87 3.87 0 0 1 92.32 59v-6.53a27 27 0 0 0-18 18h6.54a3.86 3.86 0 0 1 3.83 3.84V82a3.86 3.86 0 0 1-3.83 3.84h-6.59a27 27 0 0 0 18 18.05v-6.5a3.87 3.87 0 0 1 3.84-3.84h7.68a3.87 3.87 0 0 1 3.84 3.84v6.54a27 27 0 0 0 18-18.05h-6.54a3.86 3.86 0 0 1-3.73-3.88v-7.64a3.86 3.86 0 0 1 3.83-3.84z"/>
    {/* tslint:disable-next-line */}
    <path d="M170.17 16.15H29.83A3.82 3.82 0 0 0 26 20v116.71a3.82 3.82 0 0 0 3.83 3.82h65.34v15.74a14.92 14.92 0 0 0-9.3 9.3H42a4.83 4.83 0 1 0 0 9.66h43.87a14.92 14.92 0 0 0 28.26 0H158a4.83 4.83 0 1 0 0-9.66h-43.87a14.92 14.92 0 0 0-9.3-9.3v-15.74h65.34a3.82 3.82 0 0 0 3.83-3.82V20a3.82 3.82 0 0 0-3.83-3.85zM146.07 82a3.87 3.87 0 0 1-3.84 3.84h-8.58a34.59 34.59 0 0 1-26 26v8.58a3.87 3.87 0 0 1-3.84 3.84h-7.65a3.87 3.87 0 0 1-3.84-3.84v-8.58a34.59 34.59 0 0 1-26-26h-8.55A3.87 3.87 0 0 1 53.93 82v-7.64a3.87 3.87 0 0 1 3.84-3.84h8.58a34.59 34.59 0 0 1 26-26V36a3.87 3.87 0 0 1 3.84-3.84h7.68a3.87 3.87 0 0 1 3.81 3.84v8.58a34.59 34.59 0 0 1 26 26h8.58a3.87 3.87 0 0 1 3.84 3.84z"/>
</SvgIconMaterial>;

const WorkerPoolIcon = (props: any) => <SvgIconMaterial {...props} viewBox="0 0 200 200">
    {/* tslint:disable-next-line */}
    <path d="M30.5 13.5v172h35v-172zm26 163h-17v-17h17zM83.5 90.5v95h35v-95zm26 86h-17v-17h17zM134.5 13.5v172h35v-172zm26 163h-17v-17h17z"/>
</SvgIconMaterial>;
const WorkerIcon = (props: any) => <SvgIconMaterial {...props} viewBox="0 0 200 200">
    {/* tslint:disable-next-line */}
    <path d="M170.17 16.15H29.83A3.82 3.82 0 0 0 26 20v116.71a3.82 3.82 0 0 0 3.83 3.82h65.34v15.74a14.92 14.92 0 0 0-9.3 9.3H42a4.83 4.83 0 1 0 0 9.66h43.87a14.92 14.92 0 0 0 28.26 0H158a4.83 4.83 0 1 0 0-9.66h-43.87a14.92 14.92 0 0 0-9.3-9.3v-15.74h65.34a3.82 3.82 0 0 0 3.83-3.82V20a3.82 3.82 0 0 0-3.83-3.85zM94.06 28.08h11.88a8.35 8.35 0 0 1 6.7 3.37l-5 21.51h-15L87 31.89a8.4 8.4 0 0 1 7.06-3.81zm6 98.92a40.74 40.74 0 0 1-40-33.1h80a40.74 40.74 0 0 1-39.95 33.1zm43.4-41.44h-87a6.14 6.14 0 0 1 0-12.28h1.68a41.77 41.77 0 0 1 21.54-36.54l5.74 21.48a4.19 4.19 0 0 0 4 3.09H111a4.18 4.18 0 0 0 4-3.23l5-21.5a41.81 41.81 0 0 1 21.81 36.7h1.67a6.14 6.14 0 1 1 0 12.28z"/>
</SvgIconMaterial>;

const ExcludedMachineIcon = (props: any) => {
    return <SvgIconMaterial {...props} viewBox="0 0 200 200"
                            style={{fill: white, margin: 5, height: 19.2, width: 19.2}}>
        {/* tslint:disable-next-line */}
        <path d="M6.713 20.367l7.784-8.206 176.512 167.446-7.783 8.205z"/>
        {/* tslint:disable-next-line */}
        <path d="M158 165.57h-43.87a14.92 14.92 0 0 0-9.3-9.3v-15.74h45l-33.6-31.88a34.39 34.39 0 0 1-8.52 3.2v8.58a3.87 3.87 0 0 1-3.84 3.84h-7.71a3.87 3.87 0 0 1-3.84-3.84v-8.58a34.59 34.59 0 0 1-26-26h-8.55A3.87 3.87 0 0 1 53.93 82v-7.64a3.87 3.87 0 0 1 3.84-3.84h8.58a34.57 34.57 0 0 1 2.37-6.91L26 23.08v113.63a3.82 3.82 0 0 0 3.83 3.82h65.34v15.74a14.92 14.92 0 0 0-9.3 9.3H42a4.83 4.83 0 1 0 0 9.66h43.87a14.92 14.92 0 0 0 28.26 0H158a4.83 4.83 0 1 0 0-9.66zM75.23 54.2a34.5 34.5 0 0 1 17.09-9.65V36a3.87 3.87 0 0 1 3.84-3.84h7.68a3.87 3.87 0 0 1 3.84 3.84v8.58a34.59 34.59 0 0 1 26 26h8.58a3.87 3.87 0 0 1 3.84 3.84V82a3.87 3.87 0 0 1-3.84 3.84h-8.58a34.42 34.42 0 0 1-8.39 15.79l41 38.86h3.94a3.82 3.82 0 0 0 3.83-3.82V20a3.82 3.82 0 0 0-3.83-3.82h-135z"/>
        {/* tslint:disable-next-line */}
        <path d="M74.69 69.27c-.15.41-.3.83-.42 1.25H76zM119.71 96.4a26.81 26.81 0 0 0 6-10.52h-6.54a3.86 3.86 0 0 1-3.81-3.88v-7.64a3.86 3.86 0 0 1 3.83-3.84h6.54a27 27 0 0 0-18-18V59a3.87 3.87 0 0 1-3.84 3.84h-7.73A3.87 3.87 0 0 1 92.32 59v-6.53a26.83 26.83 0 0 0-11.53 7zM107.68 100.57v3.36c.87-.26 1.73-.58 2.57-.92zM84.64 78.72V82a3.86 3.86 0 0 1-3.83 3.84h-6.54a27 27 0 0 0 18 18.05v-6.5a3.87 3.87 0 0 1 3.84-3.84h4.12z"/>
    </SvgIconMaterial>;
};

export {
    EnvironmentIcon,
    ExcludedEnvironmentIcon,
    ProjectIcon,
    MachineIcon,
    WorkerPoolIcon,
    WorkerIcon,
    ExcludedMachineIcon
};