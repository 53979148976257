import * as React from "react";
import {ActionEditProps} from "../pluginRegistry";
import {BaseComponent} from "components/BaseComponent/BaseComponent";
import { AwsBoundAccountVariableSelect } from "components/form/AccountSelect/AccountVariableSelect";
import {ExpandableFormSection, Summary, Note, SummaryNode, RadioButton } from "components/form";
import ExternalLink from "components/Navigation/ExternalLink";
import {AWSScriptProperties} from "./awsLoginComponent";
import {ValueInPropertiesOrErrorsHasChanged} from "utils/ShouldUpdate/ValueInPropertiesHasChanged";
import { VariableLookupText } from "components/form/VariableLookupText";
import { BoundStringRadioButtonGroup } from "components/form/RadioButton/RadioButtonGroup";

const StringProperties = {
    "Octopus.Action.AwsAccount.Variable": "",
    "Octopus.Action.AwsAccount.UseInstanceRole": "",
    "Octopus.Action.Aws.AssumeRole": "",
    "Octopus.Action.Aws.AssumedRoleArn": "",
    "Octopus.Action.Aws.AssumedRoleSession": "",
    "Octopus.Action.Aws.Region": ""
};

const AllProperties = {
    ...StringProperties
};

export type AWSScriptProperties = {[P in keyof typeof StringProperties]: string};

/**
 * A component that displays the connection details for an AWS account
 */
export default abstract class AwsLoginComponent extends BaseComponent<ActionEditProps<AWSScriptProperties, any>, never> {
    static Fields: React.FC<ActionEditProps<AWSScriptProperties>> = (props) => {
        return (
            <React.Fragment>
                <BoundStringRadioButtonGroup
                resetValue={"False"}
                value={props.properties["Octopus.Action.AwsAccount.UseInstanceRole"]}
                onChange={(x) => {
                    props.setProperties({["Octopus.Action.AwsAccount.UseInstanceRole"]: x});
                    props.setProperties({["Octopus.Action.AwsAccount.Variable"]: ""});
                }}
                label="Execute using the AWS service role for an EC2 instance"
                >
                    <RadioButton value={"True"} label="Yes"/>
                    <RadioButton value={"False"} label="No"/>
                </BoundStringRadioButtonGroup>
                {
                    props.properties["Octopus.Action.AwsAccount.UseInstanceRole"] === "False" && (
                        props.projectId
                    ? <AwsBoundAccountVariableSelect
                        projectId={props.projectId}
                        resetValue={""}
                        allowClear={true}
                        doBusyTask={props.doBusyTask}
                        value={props.properties["Octopus.Action.AwsAccount.Variable"] as string}
                        onChange={(val) => props.setProperties({["Octopus.Action.AwsAccount.Variable"]: val})}
                    />
                    : <VariableLookupText label="AWS Account variable"
                                            localNames={props.localNames}
                                            projectId={props.projectId}
                                            value={props.properties["Octopus.Action.AwsAccount.Variable"] as string}
                                            onChange={(val) => props.setProperties({["Octopus.Action.AwsAccount.Variable"]: val})}
                    />)
                }
                <BoundStringRadioButtonGroup
                resetValue={"False"}
                value={props.properties["Octopus.Action.Aws.AssumeRole"]}
                onChange={(x) => props.setProperties({["Octopus.Action.Aws.AssumeRole"]: x})}
                label="Assume a different AWS service role">
                    <RadioButton value={"True"} label="Yes"/>
                    <RadioButton value={"False"} label="No" />
                </BoundStringRadioButtonGroup>
                {props.properties["Octopus.Action.Aws.AssumeRole"] === "True" &&
                    <div>
                        <VariableLookupText
                        label="Assumed role ARN"
                        localNames={props.localNames}
                        projectId={props.projectId}
                        value={props.properties["Octopus.Action.Aws.AssumedRoleArn"]}
                        onChange={(val) => props.setProperties({["Octopus.Action.Aws.AssumedRoleArn"]: val})}
                        error={props.getFieldError("Octopus.Action.Aws.AssumedRoleArn")}
                        />
                        <VariableLookupText
                        label="Assumed role session name"
                        localNames={props.localNames}
                        projectId={props.projectId}
                        value={props.properties["Octopus.Action.Aws.AssumedRoleSession"]}
                        onChange={(val) => props.setProperties({["Octopus.Action.Aws.AssumedRoleSession"]: val})}
                        error={props.getFieldError("Octopus.Action.Aws.AssumedRoleSession")}
                        />
                        <Note> Learn more about <ExternalLink href="AwsDocsRolesTermsAndConcepts">Roles Terms and Concepts</ExternalLink>.</Note>
                    </div>
                }
            </React.Fragment>
        );
    }

    static summary(properties: AWSScriptProperties): SummaryNode {
        const accountVariable = properties["Octopus.Action.AwsAccount.Variable"];
        const assumedRoleArn = properties["Octopus.Action.Aws.AssumedRoleArn"];
        const assumedRole = properties["Octopus.Action.Aws.AssumeRole"] === "True";
        const useInstanceRole = properties["Octopus.Action.AwsAccount.UseInstanceRole"] === "True";

        if (useInstanceRole) {
            return Summary.summary(<span>
                The AWS service role for an EC2 instance will be used {assumedRole && assumedRoleArn &&
                    <span> to assume the AWS service role <strong>{assumedRoleArn}</strong></span>}
            </span>);
        }

        return accountVariable
            ? Summary.summary(<span>
                The AWS account <strong>{accountVariable}</strong> will be used
                {assumedRole && assumedRoleArn && <span> to assume the AWS service role <strong>{assumedRoleArn}</strong></span>}
            </span>)
            : Summary.placeholder("The account variable has not been provided");
    }

    shouldComponentUpdate(newProps: ActionEditProps<AWSScriptProperties>): boolean {
        return ValueInPropertiesOrErrorsHasChanged(AllProperties, newProps, this.props);
    }

    render() {
        return <div>
            <ExpandableFormSection
                errorKey="Octopus.Action.AwsAccount.Variable|Octopus.Action.Aws.AssumedRoleArn|Octopus.Action.Aws.AssumedRoleSession"
                isExpandedByDefault={this.props.expandedByDefault}
                title="AWS Account"
                help="Enter the AWS account details"
                summary={AwsLoginComponent.summary(this.props.properties)}>
                <AwsLoginComponent.Fields {...this.props}/>
            </ExpandableFormSection>
        </div>;
    }
}
