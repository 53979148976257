import * as React from "react";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import BusyFromPromise from "components/BusyFromPromise";
import { primary, white, success, secondaryDark, danger, hover, secondaryText } from "theme/colors";
import * as cn from "classnames";
const styles = require("./style.less");

export interface ActionButtonProps {
    innerRef?: any;
    label: string;
    title?: string;
    busyLabel?: string;
    disabled?: Promise<any> | boolean; // Currently unsupported for Ternary buttons
    type?: ActionButtonType;
    icon?: JSX.Element;
    iconPosition?: IconPosition;
    tabIndex?: number;
    className?: string;
    keyboardFocused?: boolean;
    style?: object;
    labelProps?: any;
    onFocus?(event: React.FocusEvent<HTMLElement>): void;
    onClick?(event: any): Promise<any> | void;
}

interface ActionButtonState {
    invocation?: Promise<any>;
}

export enum ActionButtonType {
    Primary,
    Secondary,
    Ternary,
    Save,
    Delete,
    CreateRelease, // Edge case unique to our design.
    Category,
}

export enum IconPosition {
    Left,
    Right
}

export default class ActionButton extends React.Component<ActionButtonProps, ActionButtonState> {
    constructor(props: ActionButtonProps) {
        super(props);
        this.state = {};
    }

    onClick(event: any) {
        if (!this.props.onClick) {
            return;
        }

        if (event.preventDefault) {
            event.preventDefault();
        }
        const promise = (async () => await this.props.onClick(event))();
        this.setState({ invocation: promise });
        return promise;
    }

    getButton(busy: boolean, disabled: boolean) {
        const { iconPosition = IconPosition.Left, ...props } = this.props;
        const labelPosition = iconPosition === IconPosition.Left ? "after" : "before";

        const otherPropsForButtons = {
            tabIndex: props.tabIndex,
        };
        const commonLabelStyle = {
            fontSize: "0.8125rem",
        };
        const labelStyle = {
            color: props.disabled ? secondaryText : primary,
            whiteSpace: "nowrap",
            ...commonLabelStyle,
            ...props.labelProps
        };
        const label = busy && props.busyLabel ? props.busyLabel : props.label;
        const onClick = disabled ? null : (e: any) => this.onClick(e);

        switch (props.type) {

            case ActionButtonType.Primary:
                return <RaisedButton
                    ref={this.props.innerRef}
                    type="submit"
                    label={label}
                    title={props.title ? props.title : label}
                    disabled={disabled}
                    icon={props.icon}
                    labelPosition={labelPosition}
                    backgroundColor={success} // Primary's are the one thing that should stand out on the page.
                    labelColor={white}
                    labelStyle={commonLabelStyle}
                    disabledBackgroundColor={"transparent"}
                    disabledLabelColor={secondaryText}
                    onClick={onClick}
                    onFocus={props.onFocus}
                    keyboardFocused={this.props.keyboardFocused}
                    className={cn(styles.globalActionButton, (props.disabled || busy ? styles.disabled : null), styles.primaryButton, props.className)}
                    {...otherPropsForButtons}
                    children={this.props.children}
                />;

            case ActionButtonType.Category:
                return <FlatButton
                    ref={this.props.innerRef}
                    type="submit"
                    label={label}
                    title={props.title ? props.title : label}
                    disabled={disabled}
                    icon={props.icon}
                    backgroundColor={"#EFF4F8"}
                    hoverColor={"#EFF4F8"}
                    labelStyle={labelStyle}
                    labelPosition={labelPosition}
                    onClick={onClick}
                    onFocus={props.onFocus}
                    keyboardFocused={this.props.keyboardFocused}
                    style={{ border: "1px solid transparent" }}
                    className={cn(styles.globalActionButton, (props.disabled || busy ? styles.disabled : null), props.className)}
                    {...otherPropsForButtons}
                    children={this.props.children}
                />;
            case ActionButtonType.CreateRelease:
                return <RaisedButton
                    ref={this.props.innerRef}
                    type="submit"
                    keyboardFocused={this.props.keyboardFocused}
                    label={label}
                    title={props.title ? props.title : label}
                    disabled={disabled}
                    disabledBackgroundColor={hover}
                    disabledLabelColor={secondaryText}
                    backgroundColor={secondaryDark}
                    labelColor={white}
                    labelStyle={commonLabelStyle}
                    labelPosition={labelPosition}
                    onClick={onClick}
                    className={cn(styles.globalActionButton, (props.disabled || busy ? styles.disabled : null), styles.primaryButton, props.className)}
                    {...otherPropsForButtons}
                    children={this.props.children}
                />;

            case ActionButtonType.Save:
                return <RaisedButton
                    ref={this.props.innerRef}
                    type="submit"
                    keyboardFocused={this.props.keyboardFocused}
                    label={label}
                    title={props.title ? props.title : label}
                    disabled={disabled}
                    disabledBackgroundColor={"transparent"}
                    disabledLabelColor={secondaryText}
                    backgroundColor={success}
                    labelColor={white}
                    labelStyle={commonLabelStyle}
                    labelPosition={labelPosition}
                    onClick={onClick}
                    className={cn(styles.globalActionButton, (props.disabled || busy ? styles.disabled : null), styles.primaryButton, props.className)}
                    {...otherPropsForButtons}
                    children={this.props.children}
                />;

            case ActionButtonType.Delete:
                return <RaisedButton
                    ref={this.props.innerRef}
                    type="submit"
                    keyboardFocused={this.props.keyboardFocused}
                    label={label}
                    title={props.title ? props.title : label}
                    disabled={disabled}
                    backgroundColor={danger}
                    labelColor={white}
                    labelStyle={commonLabelStyle}
                    labelPosition={labelPosition}
                    onClick={onClick}
                    disabledBackgroundColor={"transparent"}
                    disabledLabelColor={secondaryText}
                    className={cn(styles.globalActionButton, (props.disabled || busy ? styles.disabled : null), styles.primaryButton, props.className)}
                    {...otherPropsForButtons}
                    children={this.props.children}
                />;

            case ActionButtonType.Ternary:
                const ternaryLabelStyle = {
                    opacity: 1,
                    fontSize: "0.8125rem",
                };
                return <FlatButton
                    onClick={onClick}
                    onFocus={props.onFocus}
                    label={this.props.label}
                    title={this.props.title ? this.props.title : this.props.label}
                    icon={this.props.icon}
                    disabled={!!this.props.disabled}
                    backgroundColor={white}
                    labelStyle={ternaryLabelStyle}
                    className={cn(styles.globalActionButton, (props.disabled || busy ? styles.ternaryDisabled : null), styles.ternaryButton, props.className)}
                    style={{ boxShadow: "none" }}
                />;

            // Fallthrough to Secondary
            default:
                // Div allows margin to be added
                return <FlatButton
                    ref={this.props.innerRef}
                    label={label}
                    title={props.title ? props.title : label}
                    labelStyle={labelStyle}
                    labelPosition={labelPosition}
                    icon={props.icon}
                    hoverColor={props.disabled ? hover : "#EFF4F8"}
                    disabled={disabled}
                    onClick={onClick}
                    onFocus={props.onFocus}
                    keyboardFocused={this.props.keyboardFocused}
                    className={cn(styles.globalActionButton, (props.disabled || busy ? styles.disabled : null), styles.secondaryButton, props.className)}
                    {...otherPropsForButtons}
                    children={this.props.children}
                />;
        }
    }

    render() {
        return <BusyFromPromise promise={this.state.invocation}>
            {(busy: boolean) => <BusyFromPromise promise={this.props.disabled}>
                {(disabled: boolean) => this.getButton(busy, disabled || busy)}
            </BusyFromPromise>}
        </BusyFromPromise>;
    }
}

export { ActionButton };