import * as React from "react";
import { client, repository } from "clientInstance";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import {default as FormBaseComponent, OptionalFormBaseComponentState} from "components/FormBaseComponent/FormBaseComponent";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import {RadioButtonGroup} from "components/form";
import EnvironmentResource from "client/resources/environmentResource";
import DeploymentTargetResource from "client/resources/machineResource";
import TenantResource from "client/resources/tenantResource";
import Summary from "components/form/Sections/Summary";
import RadioButton from "components/form/RadioButton/RadioButton";
import {cloneDeep} from "lodash";
import MachineMultiSelect from "components/MultiSelect/MachineMultiSelect";
import FormSectionHeading from "components/form/Sections/FormSectionHeading";
import EnvironmentMultiSelect from "components/MultiSelect/EnvironmentMultiSelect";
import RoleMultiSelect from "components/MultiSelect/RoleMultiSelect";
import TenantMultiSelect from "components/MultiSelect/TenantMultiSelect";
import Note from "components/form/Note/Note";
import { MachineChip, RoleChip, environmentChipList, tenantChipList, workerPoolChipList } from "components/Chips/index";
import {TaskName, default as TaskResource, NewTaskResource, AdHocScriptTaskArguments} from "client/resources/taskResource";
import * as URI from "urijs";
import routeLinks from "../../routeLinks";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import { FeatureToggle, Feature } from "components/FeatureToggle";
import { WorkerPoolMultiSelect } from "../MultiSelect";
import { WorkerMachineResource, WorkerPoolResource, Permission } from "client/resources";
import { isAllowed } from "../PermissionCheck/PermissionCheck";
import {AdHocScriptTargetArguments} from "../../client/repositories/taskRepository";

export enum TargetType {
    Machines = "Machines",
    Environments = "Environments",
    Workers = "Workers",
    WorkerPools = "WorkerPools"
}

class TargetTypeRadioButtonGroup extends RadioButtonGroup<TargetType> {}

export interface AdHocScriptState<TModel extends AdHocScriptModel> extends OptionalFormBaseComponentState<TModel> {
    environments?: EnvironmentResource[];
    machines?: DeploymentTargetResource[];
    roles?: string[];
    tenants?: TenantResource[];
    targetType?: TargetType;
    isLoaded?: boolean;
    isRetry?: boolean;
    taskIdToRedirectTo?: string;
    workers?: WorkerMachineResource[];
    workerPools?: WorkerPoolResource[];
}

export interface AdHocScriptModel {
    EnvironmentIds: string[];
    MachineIds: string[];
    TargetRoles: string[];
    TenantIds: string[];
    WorkerIds: string[];
    WorkerPoolIds: string[];
}

export default abstract class AdHocScript<TProps, TState extends AdHocScriptState<TModel>, TModel extends AdHocScriptModel>
    extends FormBaseComponent<TProps, TState, AdHocScriptModel & TModel> {
    allowedToRunOnWorkers: boolean = false;

    constructor(props: TProps) {
        super(props);

        const initialState = {};
        this.state = initialState as Readonly<TState>;
    }

    abstract getCustomInputs(): React.ReactNode;
    abstract getInitialPartialModel(taskToRetry?: TaskResource<any>): Promise<Partial<TModel>>;
    abstract getTitle(): string;
    abstract getHeading(): React.ReactNode;
    abstract wrapWithLayout(content: React.ReactNode): JSX.Element;
    abstract createAdHocScriptTask(targetTaskArguments: AdHocScriptTargetArguments): Promise<TaskResource<any>>;

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const [tenants, environments, machines, roles] =  await Promise.all([
                repository.Tenants.all(),
                repository.Environments.all(),
                repository.Machines.all(),
                repository.MachineRoles.all()
            ]);

            this.allowedToRunOnWorkers = isAllowed({ permission: Permission.WorkerEdit });
            const [workers, workerPools] = this.allowedToRunOnWorkers
                ? await Promise.all([repository.Workers.all(), repository.WorkerPools.all()])
                : await Promise.resolve([null, null]);

            const taskToRetry = await this.getTaskToRetry();

            const coreModel = await this.getInitialCoreModel(taskToRetry);
            const partialModel = await this.getInitialPartialModel(taskToRetry);
            //ToDo: Cast can be removed once this PR is in https://github.com/Microsoft/TypeScript/pull/13288
            const model = {...coreModel, ...(partialModel as any)};

            this.setState({
                environments,
                machines,
                roles,
                tenants,
                model,
                cleanModel: cloneDeep(model),
                targetType: this.determineTargetType(coreModel.EnvironmentIds, coreModel.TargetRoles, coreModel.TenantIds, coreModel.WorkerIds, coreModel.WorkerPoolIds),
                isLoaded: true,
                isRetry: !!taskToRetry,
                workers,
                workerPools
            });
        });
    }

    render() {
        const isLoaded = this.state && this.state.isLoaded;

        if (isLoaded && this.state.taskIdToRedirectTo) {
            return <InternalRedirect to={routeLinks.task(this.state.taskIdToRedirectTo).root} push={true}/>;
        }

        return this.wrapWithLayout(
            <FormPaperLayout title={isLoaded && this.getTitle()}
                             busy={this.state.busy}
                             errors={this.state.errors}
                             expandAllOnMount={isLoaded && !this.state.isRetry}
                             model={isLoaded && this.state.model}
                             cleanModel={isLoaded && this.state.cleanModel}
                             disableDirtyFormChecking={true}
                             disableKeyboardFormSubmission={true} /* AdHoc scripts potentially run against production targets, avoid accidental triggering via keyboard */
                             onSaveClick={this.run}
                             saveButtonLabel={"Run now"}
                             saveText={null}>
                {isLoaded &&
                <div>
                    {this.getHeading()}
                    <FormSectionHeading title="Targets"/>
                    <ExpandableFormSection
                        errorKey="targets"
                        title="Targets"
                        summary={this.targetsSummary()}
                        help="Select targets">
                        <TargetTypeRadioButtonGroup value={this.state.model && this.state.targetType}
                                                    onChange={(targetType) => this.setState({targetType})}>
                            <RadioButton value={TargetType.Machines} label={<span>Select individual <em>deployment targets</em> to run the script on</span>} isDefault={true}/>
                            {this.state.targetType === TargetType.Machines &&
                            <MachineMultiSelect value={this.state.model.MachineIds}
                                                items={this.state.machines}
                                                onChange={MachineIds => this.setModelState({MachineIds})} />
                            }

                            <RadioButton value={TargetType.Environments} label="Run the script on all deployment targets in set of environments, roles, and tenants"/>
                            {this.state.targetType === TargetType.Environments &&
                            <div>
                                <EnvironmentMultiSelect value={this.state.model.EnvironmentIds}
                                                        items={this.state.environments}
                                                        onChange={EnvironmentIds => this.setModelState({EnvironmentIds})} />
                                <Note>Specify the deployment targets that the script will run on by selecting their environments.</Note>
                                <Note>
                                    <strong>
                                        No environment specific variables will be available at runtime as the script only runs in the context of the deployment target
                                    </strong>.
                                </Note>

                                <RoleMultiSelect value={this.state.model.TargetRoles}
                                                 items={this.state.roles}
                                                 onChange={TargetRoles => this.setModelState({TargetRoles})} />
                                <Note>Specify the deployment targets that the script will run on by selecting their roles.</Note>

                                <FeatureToggle feature={Feature.MultiTenancy}>
                                    <TenantMultiSelect value={this.state.model.TenantIds}
                                                       items={this.state.tenants}
                                                       onChange={TenantIds => this.setModelState({TenantIds})} />
                                <Note>Specify the deployment targets that the script will run on by selecting their associated tenants.</Note>
                                </FeatureToggle>
                            </div>
                            }
                            {this.allowedToRunOnWorkers && <RadioButton value={TargetType.Workers} label={<span>Select individual <em>workers</em> to run the script on</span>}/>}
                            {this.state.targetType === TargetType.Workers &&
                            <div>
                                <MachineMultiSelect
                                    value={this.state.model.WorkerIds}
                                    items={this.state.workers}
                                    onChange={WorkerIds => this.setModelState({WorkerIds})}/>
                            </div>}
                            {this.allowedToRunOnWorkers && <RadioButton value={TargetType.WorkerPools} label="Run the script on all workers in set of worker pools"/>}
                            {this.state.targetType === TargetType.WorkerPools &&
                            <div>
                                <WorkerPoolMultiSelect
                                    value={this.state.model.WorkerPoolIds}
                                    items={this.state.workerPools}
                                    onChange={WorkerPoolIds => this.setModelState({WorkerPoolIds})}/>
                                <Note>Specify the workers that the script will run on by selecting their worker pools.</Note>
                            </div>}
                        </TargetTypeRadioButtonGroup>
                    </ExpandableFormSection>
                    {this.getCustomInputs()}
                </div>
                }
            </FormPaperLayout>
        );
    }

    private determineTargetType(environmentIds?: string[], targetRoles?: string[], tenantIds?: string[], workerIds?: string[], workerPoolIds?: string[]) {
        if (!this.isEmpty(workerIds)) { return TargetType.Workers; }
        if (!this.isEmpty(workerPoolIds)) { return TargetType.WorkerPools; }

        return this.isEmpty(environmentIds) && this.isEmpty(targetRoles) && this.isEmpty(tenantIds) ? TargetType.Machines : TargetType.Environments;
    }

    private isEmpty(values?: string[]) {
        return !values || values.length === 0;
    }

    private async getInitialCoreModel(taskToRetry?: TaskResource<any>): Promise<Partial<AdHocScriptModel>> {

        if (!taskToRetry) {
            return {
                EnvironmentIds: [],
                MachineIds: [],
                TargetRoles: [],
                TenantIds: [],
                WorkerIds: [],
                WorkerPoolIds: []
            };
        }

        return taskToRetry.Arguments as Partial<AdHocScriptModel>;
    }

    private getIdOfTaskToRetry(): any {
        const fullUrl = new URI(window.location);
        const relativeUrl = new URI(fullUrl.fragment());
        let retry = null;
        relativeUrl.hasQuery("retry", (value: any) => {
            retry = value;
        });
        return retry;
    }

    private run = async () => {

        await this.doBusyTask(async () => {

            const targetType = this.state.targetType;

            if (targetType === TargetType.Machines && this.state.model.MachineIds.length === 0) {
                    this.setError("At least one deployment target must be selected.");
                    return false;
            }

            if (targetType === TargetType.Workers && this.state.model.WorkerIds.length === 0) {
                this.setError("At least one worker must be selected.");
                return false;
            }

            if (targetType === TargetType.WorkerPools && this.state.model.WorkerPoolIds.length === 0) {
                this.setError("At least one worker pool must be selected.");
                return false;
            }

            const adHocScriptTargetArguments = {
                MachineIds: targetType === TargetType.Machines ? this.state.model.MachineIds : [],
                TenantIds: targetType === TargetType.Environments ? this.state.model.TenantIds : [],
                TargetRoles: targetType === TargetType.Environments ? this.state.model.TargetRoles : [],
                EnvironmentIds: targetType === TargetType.Environments ? this.state.model.EnvironmentIds : [],
                WorkerIds: targetType === TargetType.Workers ? this.state.model.WorkerIds : [],
                WorkerPoolIds: targetType === TargetType.WorkerPools ? this.state.model.WorkerPoolIds : [],
            };

            const task = await this.createAdHocScriptTask(adHocScriptTargetArguments);
            this.setState({taskIdToRedirectTo: task.Id});
        });
    }

    private targetsSummary() {
        switch (this.state.targetType) {
        case TargetType.Machines:
            if (this.state.model.MachineIds.length === 0) {
                return Summary.default("Select targets");
            } else {
                return Summary.summary(<span>The script will run on {this.machinesSummary()} </span>);
            }
        case TargetType.Environments:
            if (this.state.model.EnvironmentIds.length === 0 && this.state.model.TargetRoles.length === 0 && this.state.model.TenantIds.length === 0) {
                return Summary.placeholder("Select environments and/or roles");
            } else {
                return Summary.summary(<div>The script will run on targets in {this.environmentsSummary()} for {this.rolesSummary()}
                    <FeatureToggle feature={Feature.MultiTenancy}>, and {this.tenantSummary()}</FeatureToggle>
                </div>);
            }
        case TargetType.Workers:
            if (this.state.model.WorkerIds.length === 0) {
                return Summary.placeholder("Select workers");
            } else {
                return Summary.summary(<span>The script will run on {this.workersSummary()}</span>);
            }
        case TargetType.WorkerPools:
            if (this.state.model.WorkerPoolIds.length === 0) {
                return Summary.placeholder("Select worker pools");
            } else {
                return Summary.summary(<div>The script will run on workers in {this.workerPoolsSummary()}</div>);
            }
        }
    }

    private machinesSummary() {
        return this.state.model.MachineIds.map(id => <MachineChip key={id}
                                                                  machineName={this.state.machines.find(m => m.Id === id).Name}/>);
    }

    private environmentsSummary() {
        if (this.state.model.EnvironmentIds.length === 0) {
            return "all environments";
        }

        return environmentChipList(this.state.environments, this.state.model.EnvironmentIds);
    }

    private rolesSummary() {
        const roles = this.state.model.TargetRoles;
        if (roles.length === 0) {
            return "all roles";
        }

        return <span>{roles.map(name => <RoleChip key={name} role={name} />)} {roles.length > 1 ? "roles" : "role"} </span>;
    }

    private tenantSummary() {
        const tenants = this.state.model.TenantIds;
        if (tenants.length === 0) {
            return "all tenants";
        }

        return <span>{tenants.length > 1 ? "tenants" : "tenant"} {tenantChipList(this.state.tenants, this.state.model.TenantIds)}</span>;
    }

    private workersSummary() {
        return this.state.model.WorkerIds.map(id => <MachineChip key={id}
                                                                 machineName={this.state.workers.find(w => w.Id === id).Name}/>);
    }

    private workerPoolsSummary() {
        if (this.state.model.WorkerPoolIds.length === 0) {
            return "all worker pools";
        }

        return workerPoolChipList(this.state.workerPools, this.state.model.WorkerPoolIds);
    }

    private async getTaskToRetry() {
        const taskToRetryId = this.getIdOfTaskToRetry();
        return taskToRetryId ? await repository.Tasks.get(taskToRetryId) : null;
    }
}