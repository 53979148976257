import * as React from "react";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import {
    EnvironmentsSummaryResource,
    EnvironmentSummaryResource,
} from "client/resources";
import {
    EnvironmentIcon
} from "components/Icon";
import { secondaryDark, white } from "theme/colors";
import InternalLink from "components/Navigation/InternalLink";
const styles = require("./style.less");
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import { secondaryText } from "theme/colors";
import routeLinks from "../../../../routeLinks";
import BusyIndicator from "components/BusyIndicator";
import { Section } from "components/Section/Section";

interface EnvironmentsCardProps {
    environmentSummary: EnvironmentsSummaryResource;
}

interface EnvironmentsCardState extends DataBaseComponentState {
    filterText?: string;
}

export default class EnvironmentsCard extends DataBaseComponent<EnvironmentsCardProps, EnvironmentsCardState> {
    constructor(props: EnvironmentsCardProps) {
        super(props);
        this.state = ({
        });
    }

    render() {
        if (!this.props.environmentSummary) {
            return <div className={styles.card}>
                <div className={styles.cardTitleContainer}>
                    <div className={styles.cardTitle}>
                        <InternalLink to={routeLinks.infrastructure.environments.root} color={white}>
                            Environments
                        </InternalLink>
                    </div>
                </div>
                <Section><BusyIndicator inline={true} show={true} /></Section>
            </div>;
        }
        let environmentSummaries = this.props.environmentSummary && this.props.environmentSummary.EnvironmentSummaries;
        environmentSummaries = environmentSummaries.filter((item) => this.onFilter(this.state.filterText, item));
        const environmentRows = environmentSummaries && environmentSummaries.map((environmentSummary, index) => {
            const totalMachines = environmentSummary.TotalMachines || 0;
            return <div className={styles.environmentRowsContainer} key={environmentSummary.Environment.Id}>
                <div className={styles.environmentIcon}>
                    <EnvironmentIcon color={secondaryDark} />
                </div>
                <div className={styles.environmentName}>
                    <InternalLink to={routeLinks.infrastructure.machines.filtered({ environmentIds: environmentSummary.Environment.Id })}>
                        {environmentSummary.Environment.Name}
                    </InternalLink>
                </div>
                <div className={styles.environmentMachinesCount}>{totalMachines.toLocaleString()}</div>
            </div>;
        });
        return <div className={styles.card}>
            <div className={styles.cardTitleContainer}>
                <div className={styles.cardTitle}>
                    <InternalLink to={routeLinks.infrastructure.environments.root} color={white}>
                        Environments <span className={styles.cardCount}>({environmentSummaries && environmentSummaries.length.toLocaleString()})</span>
                    </InternalLink>
                </div>
            </div>
            <div className={styles.cardContentContainer}>
                {(this.props.environmentSummary.EnvironmentSummaries.length > 1 || this.state.filterText) && <div>
                    <FilterSearchBox
                        hintText="Filter..."
                        onChange={(value) => this.setState({ filterText: value.toLowerCase() })}
                        inputClassName={styles.dashboardFilterInputClassName}
                        iconColor={secondaryText}
                        iconStyle={{ width: "20px", height: "20px", top: "14px" }}
                    />
                </div>}
                {environmentRows}
            </div>
        </div>;
    }

    private onFilter(filter: string, resource: EnvironmentSummaryResource) {
        return !filter || filter.length === 0 || !resource
            || resource.Environment.Name.toLowerCase().includes(filter.toLowerCase());
    }
}
