import * as React from "react";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import Select from "../../../../components/form/Select/Select";
import Text from "../../../../components/form/Text/Text";
import OkDialogLayout from "../../../../components/DialogLayout/OkDialogLayout";
import Callout, {CalloutType} from "components/Callout/Callout";
import Note from "components/form/Note/Note";

interface ModifyTaskStateDialogProps {
    availableStates: string[];
    currentTaskState: string;
    onStateChanged(newTaskState: string, reason: string): void;
}

interface ModifyTaskStateDialogState extends DataBaseComponentState {
    newTaskState: string;
    reason: string;
}

export default class ModifyTaskStateDialog extends DataBaseComponent<ModifyTaskStateDialogProps, ModifyTaskStateDialogState> {
    constructor(props: ModifyTaskStateDialogProps) {
        super(props);
        this.state = {
            newTaskState: null,
            reason: null
        };
    }

    save = async () => {
        this.props.onStateChanged(this.state.newTaskState, this.state.reason);

        //this.props.onStateChanged();
        return true;
    }

    render() {
        const isDisabled = !this.state.newTaskState ||
            this.state.newTaskState === this.props.currentTaskState ||
            !this.state.reason ||
            this.state.reason === "";
        return <OkDialogLayout
            title="Modify Task State"
            busy={this.state.busy}
            errors={this.state.errors}
            okButtonLabel="Apply"
            okButtonDisabled={isDisabled}
            onOkClick={() => this.save()}>
            <Select label="State"
                    hintText="The desired state of this task"
                    autoFocus
                    value={this.state.newTaskState || this.props.currentTaskState}
                    items={this.props.availableStates.map(t => ({
                        value: t,
                        text: t === this.props.currentTaskState ? `${t} (current)` : t}))
                    }
                    onChange={(newState) => this.setState({newTaskState: newState})}/>
            <Text label="Reason"
                  value={this.state.reason}
                  multiLine={true}
                  onChange={(reason) => this.setState({reason})} />
            <Note>Provide a reason for changing the state of this task</Note>
            <Callout type={CalloutType.Information} title={"Auto-deploys will not be affected"}>
                Changing the state will not have any knock-on effects for auto-deploys.
                For example, changing it to successful will not cause it be treated as successful on a re-deploy trigger.
            </Callout>
        </OkDialogLayout>;
    }
}